import { css } from '@emotion/react';
import normalize from 'normalize';

import theme from 'theme';

import generalSansMedium from 'assets/fonts/GeneralSans-Medium.woff2';
import generalSansSemiBold from 'assets/fonts/GeneralSans-Semibold.woff2';
import generalSansBold from 'assets/fonts/GeneralSans-Bold.woff2';
import ExconMedium from 'assets/fonts/Excon-Medium.woff2';
import ExconBold from 'assets/fonts/Excon-Bold.woff2';

export default css`
  @font-face {
    font-family: 'General Sans Medium';
    font-style: normal;
    font-weight: normal;
    src: local('General Sans Medium'), url(${generalSansMedium}) format('woff2');
  }
  @font-face {
    font-family: 'General Sans Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('General Sans Semibold'), url(${generalSansSemiBold}) format('woff2');
  }
  @font-face {
    font-family: 'General Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('General Sans Bold'), url(${generalSansBold}) format('woff2');
  }
  @font-face {
    font-family: 'Excon Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Excon Medium'), url(${ExconMedium}) format('woff2');
  }
  @font-face {
    font-family: 'Excon Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Excon Bold'), url(${ExconBold}) format('woff2');
  }

  ${normalize}

  body {
    font-family: ${theme.fonts.GSMedium};
    font-size: ${theme.fontSizes[2]};
    line-height: ${theme.lineHeight};
    color: ${theme.colors.dark};
    background: ${theme.colors.light};
  }

  *:focus {
    /* please provide a way to know each element (that can take focus: button, a, input, el with tabindex) is focused */
    outline: none;
  }

  ::placeholder {
    opacity: 1;
    color: ${theme.colors.grays[0]};
  }

  input {
    -webkit-appearance: none;
  }

  input::-webkit-clear-button,
  input::-webkit-search-cancel-button {
    display: none;
  }

  ::-ms-clear {
    display: none;
  }

  figure {
    margin: 0;
  }
`;

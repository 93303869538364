import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { array, bool, func, number } from 'prop-types';

import { getOrderParams } from 'utils/api';
import { USER_ROLES } from 'utils/constants';
import { UserContext } from 'context';

import { Box, Button, Heading, Icon, Row } from 'components/atoms';
import { Modal, Pagination, SearchField, Table } from 'components/molecules';
import { CooptationForm } from 'components/organisms';

const Cooptations = ({ getCooptations, editCooptation, list, count, isFetchingList, isUpdating, signFile }) => {
  const currentUser = useContext(UserContext);
  const { t } = useTranslation();

  const [cooptationEdited, setCooptationEdited] = useState({});
  const [currentAction, setCurrentAction] = useState(null);
  const [criteria, setCriteria] = useState({
    page: 1,
    limit: 10,
    order: 'cooptor.firstName',
    count: true
  });
  const [where, setWhere] = useState({
    search: {
      fields: [
        'cooptor.firstName',
        'cooptor.lastName',
        'cooptor.entity.name',
        'lastName',
        'firstName',
        'commentary',
        'hrProcess.process',
        'hr.firstName',
        'hr.lastName'
      ],
      text: ''
    },
    ...(currentUser.role === USER_ROLES.USER ? { cooptorId: currentUser.userId } : {})
  });
  const columns = useMemo(
    () => [
      { Header: t('cooptation:firstNameCooptor'), accessor: 'cooptor.firstName' },
      { Header: t('cooptation:lastNameCooptor'), accessor: 'cooptor.lastName' },
      { Header: t('cooptation:entityCooptor'), accessor: 'cooptor.entity.name' },
      { Header: t('cooptation:firstName'), accessor: 'firstName' },
      { Header: t('cooptation:lastName'), accessor: 'lastName' },
      {
        Header: t('cooptation:cv'),
        Cell: (rowData) => {
          const file = rowData?.row?.original.cv;

          return file ? (
            <Button
              onClick={async (e) => {
                e.stopPropagation();
                const signedFile = await signFile(file);
                window.open(signedFile, '_blank');
              }}
              variant="secondary"
              bare
              iconOnly
              borderRadius="0"
              title={t('general:actions.download')}>
              <Icon name="MdDownload" />
            </Button>
          ) : (
            ''
          );
        }
      },
      {
        Header: t('cooptation:ml'),
        Cell: (rowData) => {
          const file = rowData?.row?.original.motivationLetter;

          return file ? (
            <Button
              onClick={async (e) => {
                e.stopPropagation();
                const signedFile = await signFile(file);
                window.open(signedFile, '_blank');
              }}
              variant="secondary"
              bare
              iconOnly
              borderRadius="0"
              title={t('general:actions.download')}>
              <Icon name="MdDownload" />
            </Button>
          ) : (
            ''
          );
        }
      },
      { Header: t('user:entity'), accessor: 'entity.name' },
      { Header: t('cooptation:job'), accessor: 'job.name' },
      {
        Header: t('general:commentary'),
        accessor: 'commentary',
        Cell: ({ value }) => value || ''
      },
      {
        Header: t('cooptation:hr'),
        accessor: 'hr',
        Cell: ({ value }) => `${value?.firstName || ''} ${value?.lastName || ''}`
      },
      {
        Header: t('cooptation:hrProcess'),
        accessor: 'hrProcess.process',
        Cell: ({ value }) => `${value}`
      },
      {
        Header: t('cooptation:contractStart'),
        accessor: 'contractStart',
        Cell: ({ value }) => (value && new Date(value).toLocaleDateString()) || ''
      },
      {
        Header: t('cooptation:workPermit'),
        accessor: 'workPermit',
        Cell: ({ value }) => t(`general:choice.${!!value}`)
      },
      {
        Header: t('cooptation:startOfCooptation'),
        accessor: 'createdAt',
        Cell: ({ value }) => (value && new Date(value).toLocaleDateString()) || ''
      },
      {
        Header: t('cooptation:endOfCooptation'),
        accessor: 'endOfCooptation',
        Cell: ({ value }) => (value && new Date(value).toLocaleDateString()) || ''
      },
      {
        id: 'actions',
        cellProps: { width: '1px', p: 0 },
        Cell: () => (
          <Row flexWrap="nowrap" height="100%">
            <Button variant="secondary" bare iconOnly borderRadius="0" title={t('user:actions.edit')}>
              <Icon name="MdEdit" />
            </Button>
          </Row>
        )
      }
    ],
    [t, signFile]
  );

  const onSearchChange = useCallback(
    (text) => setWhere((current) => ({ ...current, search: { ...current.search, text } })),
    []
  );

  const onSort = useCallback((params) => {
    const newParams = params
      .map((param) => {
        if (param.id === 'hr') {
          return [
            { ...param, id: 'hr.firstName' },
            { ...param, id: 'hr.lastName' }
          ];
        }
        return param;
      })
      .flat();
    setCriteria((current) => ({ ...current, order: getOrderParams('cooptor.firstName', newParams) }));
  }, []);

  useEffect(() => {
    getCooptations({ ...criteria, where });
  }, [getCooptations, criteria, where]);

  const closeModal = async (cooptation) => {
    if (cooptation?.cooptedId && currentAction === 'EDIT') {
      if (await editCooptation(cooptation)) toast.success(t('general:edited'));
      await getCooptations(criteria);
    }
    setCurrentAction(null);
    setCooptationEdited(null);
  };

  return (
    <Row justifyContent="center">
      <Box p="4" width="100%">
        <Row justifyContent="space-between" alignItems="center" mb="2">
          <Heading>{t('general:menu.listing')}</Heading>
          <SearchField
            width={[1, 'auto']}
            maxWidth={['initial', '300px']}
            my="2"
            onChange={(val) => onSearchChange(val)}
          />
        </Row>
        <Table
          sortable
          onSort={onSort}
          isLoading={isFetchingList}
          mb="5"
          tableParams={{
            initialState: {
              sortBy: [{ id: 'cooptor.firstName', desc: false }]
            }
          }}
          getRowProps={({ original }) => ({
            onClick: () => {
              setCooptationEdited(original);
              setCurrentAction('EDIT');
            }
          })}
          data={list}
          columns={columns}
        />
        <Pagination
          page={criteria.page}
          pages={Math.ceil(count / criteria.limit)}
          onChange={(data) => setCriteria((curr) => ({ ...curr, page: data.page }))}
        />
      </Box>
      <Modal
        show={currentAction === 'EDIT'}
        onClose={closeModal}
        title={t('general:edit')}
        actions={[
          (props) => <Button {...props} />,
          (props) => (
            <Button
              {...props}
              autoFocus
              variant="primary"
              form="cooptationForm"
              type="submit"
              onClick={() => null}
              isLoading={!!(isUpdating || isFetchingList)}>
              {t('general:save')}
            </Button>
          )
        ]}>
        <CooptationForm
          cooptation={cooptationEdited}
          type="EDIT"
          onSubmit={closeModal}
          hideActions
          id="cooptationForm"
        />
      </Modal>
    </Row>
  );
};

Cooptations.propTypes = {
  getCooptations: func.isRequired,
  editCooptation: func.isRequired,
  signFile: func.isRequired,
  list: array.isRequired,
  isFetchingList: bool.isRequired,
  isUpdating: bool.isRequired,
  count: number.isRequired
};

const mapState = (state) => ({
  list: state?.cooptation?.list || [],
  count: state?.cooptation?.listGlobalCount || 0,
  isFetchingList: state?.loading?.effects?.cooptation?.getCooptations || false,
  isUpdating: state?.loading?.effects?.cooptation?.update || false
});

const mapDispatch = ({ cooptation }) => ({
  getCooptations: cooptation.getCooptations,
  editCooptation: cooptation.editCooptation,
  signFile: cooptation.signFile
});

export default connect(mapState, mapDispatch)(Cooptations);

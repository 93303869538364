import * as service from './service';

const job = {
  state: {
    list: [],
    listGlobalCount: 0
  },
  reducers: {
    listUpdated(state, payload) {
      return {
        ...state,
        list: payload.rows || payload,
        listGlobalCount: payload.count || payload.length
      };
    }
  },
  effects: {
    async getJobs(entityId) {
      const list = await service.getJobs(entityId);
      if (list) this.listUpdated(list);
      return list;
    },
    createJob: service.createJob,
    updateJob: service.updateJob,
    deleteJob: service.deleteJob
  }
};

export default job;

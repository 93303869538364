import styled from '@emotion/styled';
import { hideVisually } from 'polished';
import { border, color, compose, flexbox, get, height, layout, space, system, typography, width } from 'styled-system';
import { isNil } from 'ramda';

import { mimicLabel } from 'components/helpers';

export const stateStyle = (p) => {
  const bg = `background: ${p.theme.colors.light};`;
  if (p.error) {
    return `
      ${bg}
      border-color: ${p.theme.colors.red};
    `;
  }
  if (p.disabled || p.readOnly) {
    return `
      border-color: transparent;
    `;
  }
  if (p.focus) {
    return `
      ${bg}
      border-color: ${p.theme.colors.dark};
    `;
  }
  return `
    ${bg}
    border-color: ${p.theme.colors.grays[1]};
  `;
};

export default {
  Container: styled.div`
    ${compose(layout, flexbox, space)}
  `,
  Main: styled.label`
    display: flex;
    flex-direction: column;
    ${compose(flexbox, mimicLabel)}
  `,
  Content: styled.div`
    ${(p) => `
      position: relative;
      flex: 1;
      display: flex;
      border-radius: ${p.theme.radii[1]};
      border-width: ${p.theme.borderWidths[1]};
      border-style: solid;
      overflow: hidden;
      transition: border-color ${p.theme.transition}, background-color ${p.theme.transition};
    `}
    ${(p) =>
      p.disabled &&
      `
      pointer-events: none;
      opacity: 0.5;
    `}
    ${(p) => p.hideInput && hideVisually()}
    ${stateStyle}
    ${compose(border, color)}
  `,
  Input: styled.input`
    ${(p) => `
      font-size: ${p.theme.fontSizes[2]};
      line-height: ${p.theme.lineHeight};
      padding-left: ${p.hasStartIcon ? '0;' : `${p.theme.space[2]};`}
      padding-right: ${p.hasEndIcon || p.hasReset ? '0;' : `${p.theme.space[2]};`}
      background: none;
      border: none;
      flex: 1;
      min-width: 0;
      resize: none;
      outline: none;
      -moz-appearance: textfield;
      ::-webkit-search-cancel-button,
      ::-webkit-clear-button,
      ::-webkit-inner-spin-button {
        display: none;
      }
    `}
    ${(p) =>
      system({
        elementSize: {
          scale: 'elementSizes',
          properties: ['paddingTop', 'paddingBottom'],
          transform: (val, scale) =>
            !isNil(val) &&
            `calc((
                ${get(scale, val)} - ${p.theme.borderWidths[1]} * 2 -
                ${p.theme.fontSizes[2]} * ${p.theme.lineHeight}
              ) / 2)`
        }
      })}
    ${(p) =>
      system({
        elementSize: {
          scale: 'elementSizes',
          property: 'height',
          transform: (val, scale) => {
            if (isNil(val)) return null;
            return p.isTextarea
              ? `calc(${get(scale, val)} - 
                ${p.theme.borderWidths[1]} * 2 + 
                ${p.theme.fontSizes[2]} * 
                ${p.theme.lineHeight * 4});`
              : `calc(${get(scale, val)} - ${p.theme.borderWidths[1]} * 2)`;
          }
        }
      })}
    ${compose(
      color,
      space,
      height,
      width,
      typography,
      system({
        cursor: true
      })
    )}
  `
};

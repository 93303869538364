// eslint-disable-next-line import/prefer-default-export
export const entities = [
  {
    entityId: 'd477ae55-1ea8-4e31-b160-8e9bb73edc5f',
    emailDomain: 'alispharm.tech',
    name: 'Alispharm',
    jobs: [
      {
        jobId: '71efcf5a-34aa-4817-8125-65274473502d',
        name: 'Commissionning Engineer'
      },
      {
        jobId: 'a819fe4f-6e47-4dfd-88df-687252a344a3',
        name: 'Control Quality Engineer'
      },
      {
        jobId: 'ad36755f-d464-4475-832f-504c930cad0f',
        name: 'Quality assurance'
      },
      {
        jobId: '38dbad6b-7e30-4ffd-91ba-09f13ad24f56',
        name: 'R&D medical devices consultant'
      },
      {
        jobId: '6a12c33c-14e0-4987-b338-37154f712069',
        name: 'Regulatory Affairs Specialist'
      },
      {
        jobId: 'aef43a35-b44b-4ee9-b47e-8e0cd581e604',
        name: 'Risk Management Consultant'
      }
    ]
  },
  {
    entityId: '8a6bd83d-d499-47d6-b548-1ce4c26d02ec',
    emailDomain: 'cbtw.tech',
    name: 'CBTW',
    jobs: [
      {
        jobId: '54a7fd81-33a5-484e-864d-e8ef50ea7854',
        name: 'Accountant'
      },
      {
        jobId: '48668434-e544-412c-8fe7-7e7c6a8078fc',
        name: 'Admin Specialist'
      },
      {
        jobId: '6ae1434e-0c1e-4da2-b6fd-62c63bd9c6af',
        name: 'Business Facilitatior'
      },
      {
        jobId: '6b622dff-db20-4cd2-a101-82f03f652dc3',
        name: 'Business Manager'
      },
      {
        jobId: '15bc18cd-d830-4cbc-886f-5ba40a08d77c',
        name: 'Career&Evolution Officer'
      },
      {
        jobId: '99aceb25-fab0-45cb-8722-ac55df4f126d',
        name: 'IT Service Desk Officer'
      },
      {
        jobId: 'c5a1d3fc-3088-4f58-90b7-877249e40284',
        name: 'Legal Officer'
      },
      {
        jobId: '4b328d2b-dd66-4389-8907-8aec15c32565',
        name: 'Marketing and Communication Specialist'
      },
      {
        jobId: '769a78d0-4e3c-41f4-a85a-9286d320e0ac',
        name: 'Talent Acquisition Specialist'
      }
    ]
  },
  {
    entityId: 'b8aa2403-8aa6-4fdc-8acf-d09f7b66e9b9',
    emailDomain: 'otofacto.tech',
    name: 'Otofacto',
    jobs: [
      {
        jobId: '052507fe-6059-4049-b574-c8b3b25e7671',
        name: 'Automation Engineer'
      },
      {
        jobId: 'c59e9f96-dc0c-4e90-9f04-df0e4783eed4',
        name: 'Digital Engineer'
      },
      {
        jobId: '65732c51-0d56-46ba-accb-d1007b5aed56',
        name: 'Electromechanical Engineer'
      },
      {
        jobId: '285139d4-e890-4c6e-a806-082ece9febf5',
        name: 'Embedded Software Engineer'
      },
      {
        jobId: 'c50e87e9-9efe-422e-8b1d-84c65d8f8b7c',
        name: 'Maintenance & Reliability Engineer'
      },
      {
        jobId: 'f1a013b6-3a03-4938-b7ed-cecdc4f6bc3b',
        name: 'Process Engineer'
      },
      {
        jobId: '21c4388a-329c-4bcc-8f22-dd054a9c4af9',
        name: 'Project Engineer'
      }
    ]
  },
  {
    entityId: 'df0ff8c0-accd-4303-8ab3-22f2a398be5e',
    emailDomain: 'positivethinking.tech',
    name: 'PTC',
    jobs: [
      {
        jobId: '32b7c375-3f25-4506-85d6-71b7378c296a',
        name: 'BI Specialist'
      },
      {
        jobId: '3dfefbdf-f4a3-44bd-ba4a-924eecf84419',
        name: 'Cloud Engineer'
      },
      {
        jobId: '17c8bbe4-a750-49ed-8a6c-a99b6b26d262',
        name: 'Data Analyst'
      },
      {
        jobId: '5ee7754a-1015-49a2-a78b-6d921e49b902',
        name: 'Data Engineer'
      },
      {
        jobId: 'a60a488e-6f71-41de-92cb-4df498fe588b',
        name: 'Data Scientist'
      },
      {
        jobId: '1a0a27cb-db41-4f88-80f0-97363e735ce4',
        name: 'Developer'
      },
      {
        jobId: 'c5e1b4b7-3216-46ae-899d-aacbf022f12c',
        name: 'Functional Analyst'
      },
      {
        jobId: '64438c9d-3960-498f-8c60-3bfabd7f81d9',
        name: 'IT Project Manager'
      },
      {
        jobId: 'ab141b28-05b6-4aef-9564-5825795487a7',
        name: 'Product Owner'
      },
      {
        jobId: '12f7e065-564a-4afc-81e9-cb13db3c9ebe',
        name: 'Scrum Master'
      },
      {
        jobId: '08b071b0-b7b1-4bc6-9c88-081e9f891f17',
        name: 'Tester'
      }
    ]
  },
  {
    entityId: 'bb872f0e-87bd-45c9-9b84-80b9103d1b89',
    emailDomain: 'steepconsult.tech',
    name: 'SteepConsult',
    jobs: [
      {
        jobId: 'cede9f02-fdf5-49e2-acd0-89c078113267',
        name: 'Agile Coach'
      },
      {
        jobId: '4d64d388-b88a-46a6-8bb5-198507d9545f',
        name: 'Business Analyst'
      },
      {
        jobId: 'b33fa65f-83ac-488f-b32f-67a20a089c12',
        name: 'Business Strategist'
      },
      {
        jobId: '858ae615-05a6-4151-9450-64c624b40497',
        name: 'Change Manager'
      },
      {
        jobId: 'f95e60f4-1180-43d6-ab6f-9cc20c040584',
        name: 'Process Analyst'
      },
      {
        jobId: 'd330ff06-cd58-44c1-af2f-aedec5cca2e9',
        name: 'Project Manager'
      }
    ]
  },
  {
    entityId: '924ab966-bde2-4480-81d6-a4f0ac922675',
    emailDomain: 'versett.tech',
    name: 'Versett',
    jobs: [
      {
        jobId: '9172b1b8-ab0d-440e-be38-c0d3b77ce4e8',
        name: 'Digital Business Analyst'
      },
      {
        jobId: 'dbc8534f-70fc-4257-b604-5414b0c684a3',
        name: 'Digital Project Manager'
      },
      {
        jobId: 'e629c5e2-0da8-4933-91dd-10f98c8c75e4',
        name: 'Marketing Data Analyst'
      },
      {
        jobId: 'ab141b28-05b6-4aef-9564-5825795487a7',
        name: 'Product Owner'
      },
      {
        jobId: '7b7b86f7-3930-4ecd-b1ea-fd745a392f9a',
        name: 'UX/UI Designer'
      }
    ]
  }
];

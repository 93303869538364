import { PASSWORD_MIN_LENGTH, REGEXES } from 'utils/constants';
import inputValidation from './input';

export default (getValues = () => null, passwordRequired = false, allowedEmailDomains = []) => ({
  firstName: {
    required: 'firstNameRequired',
    maxLength: inputValidation.text.maxLength,
    pattern: {
      value: REGEXES.NAME,
      message: 'firstNameInvalid'
    }
  },
  lastName: {
    required: 'lastNameRequired',
    maxLength: inputValidation.text.maxLength,
    pattern: {
      value: REGEXES.NAME,
      message: 'lastNameInvalid'
    }
  },
  email: {
    required: 'emailRequired',
    maxLength: inputValidation.text.maxLength,
    pattern: {
      value: REGEXES.EMAIL,
      message: 'emailInvalid'
    }
  },
  emailCorporate: {
    required: 'emailRequired',
    maxLength: inputValidation.text.maxLength,
    validate: (value) => allowedEmailDomains.some((email) => value.endsWith(`@${email}`)) || 'unauthorizedEmail',
    pattern: {
      value: REGEXES.EMAIL,
      message: 'emailInvalid'
    }
  },
  entity: {
    required: 'required'
  },
  role: {
    required: 'required'
  },
  password: {
    required: passwordRequired ? 'passwordRequired' : false,
    minLength: {
      value: PASSWORD_MIN_LENGTH,
      message: 'passwordTooShort'
    }
  },
  passwordConfirmation: {
    required: passwordRequired ? 'passwordConfirmationRequired' : false,
    validate: (value) => value === getValues().password || 'passwordShouldMatch'
  }
});

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { array, bool, func, object, string } from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { searchRequest } from 'utils/api';

import { getError } from 'components/helpers';
import { Box, Button, Error, Row } from 'components/atoms';
import { CheckBox, Field, Modal, Select, UploadFile } from 'components/molecules';

import { UserContext } from 'context';
import { USER_GROUPS, USER_ROLES } from 'utils/constants';
import { generateValidExtensions } from 'validations/file';
import { fileValidation, inputValidation, userValidation } from 'validations';
import { omit } from 'ramda';

const CooptationForm = ({
  cooptation,
  type,
  onSubmit,
  submitting,
  entities,
  hrProcess,
  getEntities,
  getUsers,
  getHrProcess
}) => {
  const { t } = useTranslation();
  const currentUser = useContext(UserContext);

  const [newCooptation, setNewCooptation] = useState({});
  const [isCooptor, setIsCooptor] = useState(!cooptation?.lastNameCooptor);
  const [jobs, setJobs] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      isCooptor,
      ...cooptation,
      contractStart: cooptation?.contractStart?.split('T')?.shift()
    }
  });

  // INIT
  const userValidator = useMemo(() => userValidation(getValues, false), [getValues]);

  const isCooptorChanged = useCallback(
    (value) => {
      if (value) {
        ['firstNameCooptor', 'lastNameCooptor', 'emailCooptor', 'entityCooptor'].forEach((field) =>
          setValue(field, null)
        );
      }
      setIsCooptor(value);
    },
    [setValue]
  );

  useEffect(() => getEntities({ where: { countryId: currentUser.countryId } }), [getEntities, currentUser]);

  useEffect(() => getHrProcess({}), [getHrProcess]);

  const getHRUsers = useCallback(
    ({ filter, page }) =>
      searchRequest(getUsers, {
        page,
        order: 'firstName',
        limit: 10,
        where: {
          ...(filter && {
            search: {
              fields: ['firstName', 'lastName'],
              text: filter
            }
          }),
          role: USER_ROLES.HR
        }
      }),
    [getUsers]
  );

  useEffect(() => {
    register('cv', fileValidation.file(type === 'CREATE', ['pdf', 'doc', 'image']));
  }, [register, type]);

  useEffect(() => {
    register('motivationLetter', fileValidation.file(false, ['pdf', 'doc', 'image']));
  }, [register]);

  // CHANGES
  const entityChanged = useCallback(
    (entity) => {
      const job = (entity?.entityId === cooptation?.entityId && cooptation?.job) || null;
      setValue('entity', entity);
      setValue('job', job);

      if (entity) {
        const currentEntity = (entity.jobs && entity) || entities?.find((_entity) => _entity.name === entity.name);
        setJobs(currentEntity?.jobs);
      }
    },
    [setValue, cooptation, entities]
  );

  useEffect(() => entityChanged(getValues('entity')), [entityChanged, getValues]);

  // ACTIONS
  function openConfirmModal(form) {
    setNewCooptation(form);
    setIsModalOpened(true);
  }

  function submit(form) {
    setIsModalOpened(false);
    onSubmit({
      ...omit(
        ['cooptor', 'entity', 'hr', 'hrProcess', 'job', 'entityId', 'jobId', 'hrId', 'hrProcessId', 'contractStart'],
        form
      ),
      workPermit: form?.workPermit || false,
      commentary: form?.commentary || '',
      ...(form?.entity?.entityId && { entityId: form?.entity?.entityId }),
      ...(form?.job?.jobId && { jobId: form?.job?.jobId }),
      ...(form?.hr?.userId && { hrId: form?.hr?.userId }),
      ...(form?.hrProcess?.hRProcessId && { hrProcessId: form?.hrProcess?.hRProcessId }),
      ...(form?.contractStart && { contractStart: form?.contractStart })
    });
  }
  return (
    <form
      onSubmit={handleSubmit((form) => (type === 'CREATE' ? openConfirmModal(form) : submit(form)))}
      id="cooptationForm">
      <Field
        {...register('firstName', userValidator.firstName)}
        type="text"
        label={`${t('cooptation:firstName')} *`}
        error={t(getError(['firstName', 'message'], errors))}
      />

      <Field
        {...register('lastName', userValidator.lastName)}
        type="text"
        label={`${t('cooptation:lastName')} *`}
        error={t(getError(['lastName', 'message'], errors))}
      />

      <Field
        {...register('email', userValidator.email)}
        type="email"
        autoComplete="email"
        label={`${t('cooptation:email')} *`}
        error={t(getError(['email', 'message'], errors))}
      />
      {USER_GROUPS.ADMIN.includes(currentUser.role) && type === 'EDIT' && (
        <>
          <Controller
            control={control}
            name="entity"
            render={({ field: { onBlur, value } }) => (
              <Select
                label={t('cooptation:entityCoopted')}
                options={entities}
                searchable
                getOptionLabel={(e) => e?.name}
                onChange={entityChanged}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="job"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label={t('cooptation:job')}
                options={jobs}
                searchable
                getOptionLabel={(e) => e?.name}
                disabled={!getValues('entity')}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />

          <Field
            {...register('commentary', inputValidation.text)}
            type="text"
            label={t('general:commentary')}
            error={t(getError(['commentary', 'message'], errors))}
          />

          <Controller
            control={control}
            name="hr"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label={t('cooptation:hr')}
                loadOptions={getHRUsers}
                searchable
                getOptionLabel={(e) => e && `${e?.firstName || ''} ${e?.lastName || ''}`}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />

          <Controller
            control={control}
            name="hrProcess"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label={t('cooptation:hrProcess')}
                options={hrProcess}
                searchable
                getOptionLabel={(e) => e && `${e?.process}`}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />

          <Field type="date" {...register('contractStart')} label={t('cooptation:contractStart')} mr={4} />

          <CheckBox {...register('workPermit')} type="checkbox" label={t('cooptation:workPermit')} mb="1" />
        </>
      )}
      <Row justifyContent="center">
        <label htmlFor="cv">{`${t('cooptation:cv')} *`}</label>
        <UploadFile
          name="cv"
          id="cv"
          accept={generateValidExtensions(['pdf', 'doc', 'image'])}
          alt={t('cooptation:cv')}
          size="40px"
          width="100%"
          mb="2"
          iconName="MdFileUpload"
          onChange={(file) => file && setValue('cv', file, true)}
        />
        <Box width="100%" textAlign="center" mb="4">
          <Error>{t(getError(['cv', 'message'], errors))}</Error>
        </Box>
      </Row>

      <Row justifyContent="center">
        <label htmlFor="motivationLetter">{t('cooptation:motivationLetter')}</label>
        <UploadFile
          name="motivationLetter"
          id="motivationLetter"
          accept={generateValidExtensions(['pdf', 'doc', 'image'])}
          alt={t('cooptation:motivationLetter')}
          size="40px"
          width="100%"
          mb="2"
          iconName="MdFileUpload"
          onChange={(file) => file && setValue('motivationLetter', file, true)}
        />
        <Box width="100%" textAlign="center" mb="4">
          <Error>{t(getError(['motivationLetter', 'message'], errors))}</Error>
        </Box>
      </Row>

      {type === 'CREATE' && (
        <>
          <Box mb="2">
            <CheckBox
              {...register('isCooptor')}
              type="checkbox"
              label={t('cooptation:isCooptor')}
              onCheckedChange={isCooptorChanged}
              mb="1"
            />
          </Box>
          {!isCooptor && (
            <>
              <Field
                {...register('firstNameCooptor', userValidator.firstName)}
                type="text"
                label={`${t('cooptation:firstNameCooptor')} *`}
                error={t(getError(['firstNameCooptor', 'message'], errors))}
              />

              <Field
                {...register('lastNameCooptor', userValidator.lastName)}
                type="text"
                label={`${t('cooptation:lastNameCooptor')} *`}
                error={t(getError(['lastNameCooptor', 'message'], errors))}
              />

              <Field
                {...register('emailCooptor', userValidator.email)}
                type="email"
                autoComplete="email"
                label={`${t('cooptation:emailCooptor')} *`}
                error={t(getError(['emailCooptor', 'message'], errors))}
              />

              <Controller
                control={control}
                name="entityCooptor"
                rules={userValidator.entity}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    label={`${t('cooptation:entityCooptor')} *`}
                    options={entities}
                    searchable
                    getOptionLabel={(e) => e?.name}
                    error={t(getError(['entityCooptor', 'message'], errors))}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                )}
              />
            </>
          )}
        </>
      )}

      <Row alignItems="center" gutter="2">
        {type === 'CREATE' && (
          <Box px="2">
            <Button name="submit" type="submit" variant="primary" isLoading={submitting}>
              {t('general:submit')}
            </Button>
          </Box>
        )}
      </Row>

      <Modal
        show={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        title={t('cooptation:submit')}
        actions={[
          (props) => <Button {...props} />,
          (props) => (
            <Button {...props} onClick={() => submit(newCooptation)} autoFocus variant="primary">
              {t('general:confirm')}
            </Button>
          )
        ]}
      />
    </form>
  );
};

CooptationForm.propTypes = {
  cooptation: object,
  type: string,
  onSubmit: func.isRequired,
  submitting: bool,
  getUsers: func.isRequired,
  getEntities: func.isRequired,
  getHrProcess: func.isRequired,
  entities: array.isRequired,
  hrProcess: array.isRequired
};

CooptationForm.defaultProps = {
  submitting: false,
  cooptation: null,
  type: 'CREATE'
};

const mapState = ({ entity, hrProcess }) => ({
  entities: entity.list || [],
  hrProcess: hrProcess.list || []
});

const mapDispatch = ({ user, entity, hrProcess }) => ({
  getUsers: user.getUsers,
  getEntities: entity.getEntities,
  getHrProcess: hrProcess.getHrProcess
});

export default connect(mapState, mapDispatch)(CooptationForm);

import styled from '@emotion/styled';
import Box from '../Box/Box';

const circle = `
border: 1px solid #6b6a6a;
-webkit-border-radius: 50%;
padding: 1%;
position: absolute;
opacity: 0;
-webkit-animation: pulse 18s linear infinite;

@-webkit-keyframes pulse {
  0% {
    padding: 0%;
    opacity: 1;
  }

  100% {
    padding: 100%;
  }
}
`;

export default {
  Container: styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Circle1: styled(Box)`
    ${circle}
  `,
  Circle2: styled(Box)`
    ${circle}
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    animation-delay: 3s;
  `,
  Circle3: styled(Box)`
    ${circle}
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    animation-delay: 6s;
  `,
  Circle4: styled(Box)`
    ${circle}
    -webkit-animation-delay: 9s;
    -moz-animation-delay: 9s;
    animation-delay: 9s;
  `,
  Circle5: styled(Box)`
    ${circle}
    -webkit-animation-delay: 12s;
    -moz-animation-delay: 12s;
    animation-delay: 12s;
  `,
  Circle6: styled(Box)`
    ${circle}
    -webkit-animation-delay: 15s;
    -moz-animation-delay: 15s;
    animation-delay: 15s;
  `
};

// eslint-disable-next-line import/prefer-default-export
export const hrProcess = [
  { process: 'Cooptation Received', hRProcessId: 'a60755bd-1f66-484e-963b-4cbca8bf3171' },
  { process: 'First contact (Phone Call)', hRProcessId: '98aefabe-6d68-4971-b460-8b23ed37ce08' },
  { process: 'First Interview', hRProcessId: '7ac61f90-491b-4a5b-be01-4d6c1a160d62' },
  { process: 'Online test (Coding Game)', hRProcessId: '25d2da9e-260d-49c6-8315-8b381bfd48f6' },
  { process: 'Technical Validation', hRProcessId: 'bda45880-8ba3-4377-aef5-f76ad8587d43' },
  { process: 'Functional Validation', hRProcessId: 'f67c2094-4e76-4290-b880-b4b0820949e7' },
  { process: 'BM Validation', hRProcessId: 'eafb5886-35ce-4fab-b06c-8126f738af4a' },
  { process: 'BUM Validation', hRProcessId: 'ed2fb1e4-436d-4851-ba7a-5f7fdbf7349e' },
  { process: 'Team manager Validation', hRProcessId: '6215c065-8f31-46cd-b4a9-84b355dd87c7' },
  { process: 'Offer sent', hRProcessId: '49fb783a-1826-46af-a62a-8a4baa79e34e' },
  { process: 'Recruited', hRProcessId: 'a8a281d5-a9f6-47b1-ab20-c9668dd8158f' },
  { process: 'Dropped/Refused', hRProcessId: '85b526d4-b52f-4e70-b18b-1d9e8a1f6b5b' }
];

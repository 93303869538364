import * as service from './service';

const country = {
  state: {
    list: [],
    listGlobalCount: 0
  },
  reducers: {
    listUpdated(state, payload) {
      return {
        ...state,
        list: payload.rows || payload,
        listGlobalCount: payload.count || payload.length
      };
    }
  },
  effects: {
    async getCountries(criteria) {
      const list = await service.getCountries(criteria);
      if (list) this.listUpdated(list);
      return list;
    },
    createCountry: service.createCountry,
    updateCountry: service.updateCountry,
    deleteCountry: service.deleteCountry
  }
};

export default country;

import styled from '@emotion/styled';

import { boxProps, ellipsis } from 'components/helpers';

export default {
  Text: styled.p`
    ${ellipsis}
    ${boxProps}
    strong {
      ${(p) => `
        font-family: ${p.theme.fonts?.GSBold};
      `}
    }
  `
};

import styled from '@emotion/styled/macro';
import { space } from 'styled-system';
import { hideVisually } from 'polished';

import { Icon } from 'components/atoms';

const centered = `
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const CheckedIcon = styled(Icon)`
  ${centered}
`;
const ClosedIcon = styled(Icon)`
  ${centered}
`;
const CircleIcon = styled.i`
  background: ${(p) => p.theme.colors.light};
  border-radius: 50%;
  width: 6px;
  height: 6px;
  ${centered}
`;

export default {
  CheckBox: styled.div`
    ${space}
  `,
  Label: styled.label`
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
    ${(p) => !p.disabled && !p.readOnly && 'cursor: pointer;'}
    ${(p) => p.disabled && 'opacity: 0.5;'}
  `,
  Input: styled.input`
    ${hideVisually()}
  `,
  MimicBox: styled.span`
    ${(p) => `
      position: relative;
      min-width: 16px;
      min-height: 16px;
      border-radius: ${p.type === 'checkbox' ? `${p.theme.radii[1]}` : '50%'};
      border-width: ${p.theme.borderWidths[1]};
      border-style: solid;
      input:checked + & ${ClosedIcon}, input:not(:checked) + & ${CheckedIcon}, input:not(:checked) + & ${CircleIcon} {
        display: none;
      }
    `}
    ${(p) =>
      p.readOnly &&
      `
      border-color: transparent;
      color: ${p.error ? p.theme.colors.red : p.theme.colors.dark};
    `}
    ${(p) =>
      !p.readOnly &&
      `
      color: ${p.theme.colors.light};
      input:not(:checked):focus + &,
      input:checked + & {
        border-color: ${p.error ? p.theme.colors.red : p.theme.colors.dark};
      }
      input:checked + & {
        background: ${p.theme.colors.dark};
      }
      input:not(:checked) + & {
        background: ${p.theme.colors.light};
        border-color: ${p.error ? p.theme.colors.red : p.theme.colors.grays[2]};
      }
    `}
  `,
  CheckedIcon,
  ClosedIcon,
  CircleIcon
};

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';

import { Box, Card, Heading, Row } from 'components/atoms';
import { UserShortForm } from 'components/organisms';

const ForgotPassword = ({ isLoading, forgotPassword }) => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const forgot = useCallback(
    async (data) => {
      const success = await forgotPassword(data);
      if (success || success === '') navigate('/');
    },
    [forgotPassword, navigate]
  );

  return (
    <Row justifyContent="center">
      <Box p="4" maxWidth="400px" width="100%">
        <Card p="5">
          <Heading mb="2">{t('auth:forgotPassword')}</Heading>
          <UserShortForm onSubmit={forgot} submitting={isLoading} type="forgotPassword" />
        </Card>
      </Box>
    </Row>
  );
};

ForgotPassword.propTypes = {
  isLoading: bool.isRequired,
  forgotPassword: func.isRequired
};

const mapState = ({ loading }) => ({
  isLoading: pathOr(false, ['effects', 'user', 'forgotPassword'], loading)
});

const mapDispatch = (dispatch) => ({
  forgotPassword: dispatch.user.forgotPassword
});

export default connect(mapState, mapDispatch)(ForgotPassword);

import i18n from 'translate/i18n';

import { Button, Row } from 'components/atoms';

const Translate = () => {
  return (
    <Row>
      {i18n.availableLanguages?.map((lang) => (
        <Button
          key={lang}
          type="button"
          bare={i18n.language.startsWith(lang)}
          variant="tertiary"
          onClick={() => i18n.changeLanguage(lang)}
          minWidth={36}
          px={1}
          mr={2}
          fontWeight={0}
          borderRadius={50}>
          {lang.toUpperCase()}
        </Button>
      ))}
    </Row>
  );
};

export default Translate;

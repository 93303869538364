import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Global, ThemeProvider } from '@emotion/react';

import globalStyles from 'globalstyles';
import store from 'store';
import baseTheme, { createTheme } from 'theme';
import 'translate/i18n';
import { setupApi } from 'utils/api';
import history from 'utils/history';

import { Router } from 'components/atoms';
import App from 'App';

import reportWebVitals from './reportWebVitals';

setupApi(store);
store.dispatch.user.initUser();

const theme = createTheme(baseTheme);

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Global styles={globalStyles} />
          <App />
        </Router>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useCallback, useEffect, useState } from 'react';
import { any, array, bool, func, number, object, oneOfType, string } from 'prop-types';
import { useId } from 'react-id-generator';
import { or } from 'ramda';

import { usePrevious } from 'hooks';

import { Icon } from 'components/atoms';
import { getRadiusProps } from 'components/helpers';

import Styled from './UploadFile.styled';

const UploadFile = ({
  name,
  accept,
  src,
  alt,
  id,
  value,
  onChange,
  objectFit,
  objectPosition,
  aspectRatio,
  renderIcon,
  iconSize,
  iconName,
  disabled,
  readOnly,
  ...props
}) => {
  const defaultId = useId(1, 'uploadpicture')[0];
  const finalId = or(id, defaultId);
  const prevSrc = usePrevious(src, true);
  const [error, setError] = useState(false);
  const [focus, setFocus] = useState(false);
  const [fileName, setFileName] = useState();
  const [innerSrc, setInnerSrc] = useState(src);
  const [isLoading, setIsLoading] = useState(!!src);
  const [innerValue, setInnerValue] = useState(value);

  const hasFile = useCallback(() => innerSrc && !error && !isLoading, [innerSrc, error, isLoading]);

  const change = useCallback(
    (changeVal) => {
      setFileName(changeVal?.name);
      onChange(changeVal);
      if (value === undefined) setInnerValue(changeVal);
    },
    [value, onChange]
  );

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    if (innerValue) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setInnerSrc(e.target.result);
      };
      reader.readAsDataURL(innerValue);
    }
  }, [innerValue]);

  useEffect(() => {
    if (src !== prevSrc) {
      setInnerSrc(src);
      setError(false);
      setIsLoading(true);
    }
  }, [src, prevSrc]);

  return (
    <>
      <Styled.UploadFile
        {...getRadiusProps(props)}
        htmlFor={finalId}
        focus={focus}
        readOnly={readOnly}
        disabled={disabled}
        {...props}>
        <Styled.Input
          readOnly={readOnly}
          disabled={disabled}
          accept={accept}
          name={name}
          type="file"
          id={finalId}
          onChange={({ target }) => {
            change(target?.files?.[0]);
          }}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
        />
        <Styled.Picture
          {...getRadiusProps(props)}
          focus={focus}
          objectFit={objectFit}
          objectPosition={objectPosition}
          aspectRatio={aspectRatio}
          iconSize={iconSize}
          iconName={iconName}
          renderIcon={renderIcon}
          alt={alt}
          hasFile={hasFile}
          onError={() => {
            setError(true);
            setIsLoading(false);
          }}
          onLoad={() => {
            setError(false);
            setIsLoading(false);
          }}
        />
        <Styled.Placeholder {...getRadiusProps(props)} focus={focus} hasFile={hasFile}>
          <Icon name="MdFileUpload" size={iconSize} />
        </Styled.Placeholder>
      </Styled.UploadFile>
      {fileName && <p>{fileName}</p>}
    </>
  );
};

UploadFile.propTypes = {
  /** input name */
  name: string,
  /** img src */
  src: string,
  /** img alt */
  alt: string,
  /** input id, should be set for maximum compatibility */
  id: string,
  /** input accept */
  accept: string,
  /** icon size */
  iconSize: oneOfType([number, string, array, object]),
  /** icon name */
  iconName: string,
  /** picture placeholder icon element */
  renderIcon: func,
  /** input value */
  value: any,
  /** on file change */
  onChange: func,
  /** disabled */
  disabled: bool,
  /** readOnly */
  readOnly: bool,
  /** styled-system object-fit prop */
  objectFit: oneOfType([string, array, object]),
  /** styled-system object-position prop */
  objectPosition: oneOfType([string, array, object]),
  /** styled-system aspect-ratio prop */
  aspectRatio: oneOfType([string, array, object])
};

UploadFile.defaultProps = {
  name: null,
  src: null,
  alt: null,
  id: null,
  accept: '.pdf,.docx',
  renderIcon: (props) => <Icon {...props} />,
  iconSize: '30%',
  iconName: 'MdImage',
  value: undefined,
  onChange: () => null,
  disabled: false,
  readOnly: false,
  objectFit: 'cover',
  objectPosition: 'center',
  aspectRatio: null
};

export default UploadFile;

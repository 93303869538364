import { mergeAll, omit } from 'ramda';
import { toast } from 'react-toastify';
import i18n from 'translate/i18n';

import { cleanObj } from 'utils/object';
import { removeStorage } from 'utils/storage';

import * as service from './service';

const user = {
  state: {
    // initial state
    isInitialized: false,
    isLogged: false,
    current: {},
    list: [],
    listGlobalCount: 0
  },
  reducers: {
    // handle state changes with pure functions
    userUpdated(state, payload) {
      return {
        ...state,
        current: payload
      };
    },
    loggedIn(state, payload) {
      return {
        ...state,
        current: payload,
        isLogged: true,
        isInitialized: true
      };
    },
    loggedOut(state, _payload) {
      return {
        ...state,
        current: null,
        isLogged: false,
        isInitialized: true
      };
    },
    listUpdated(state, payload) {
      return {
        ...state,
        list: payload.rows ? payload.rows : payload,
        listGlobalCount: payload.count ? payload.count : payload.length
      };
    }
  },
  effects: {
    async initUser() {
      const userData = await service.initWithToken();
      if (userData) this.loggedIn(userData);
      else this.logout(false);
    },

    async update(payload, rootState) {
      const { userId } = payload;
      const promises = [service.update(userId, cleanObj(omit(['picture'], payload)))];
      if (payload.picture) promises.push(service.handlePicture(userId, payload.picture));
      const results = await Promise.all(promises);
      if (userId === rootState.user.current?.userId)
        this.userUpdated({ ...rootState.user.current, ...mergeAll(results) });

      if (results[0] || results[0] === '') toast.success(i18n.t('toast:Sucess'));
      return results[0];
    },

    async login(payload) {
      const userData = await service.login(omit(['redirectPath'], payload));
      if (userData) {
        this.loggedIn(userData);
        return true;
      }
      this.logout();
      return false;
    },

    logout() {
      removeStorage('token');
      removeStorage('id');
      this.loggedOut();
    },

    async getUsers(criteria) {
      const list = await service.getUsers(criteria);
      if (list) this.listUpdated(list);
      return list;
    },

    async register(newUser) {
      const content = await service.register(newUser);

      if (content || content === '') toast.success(i18n.t('toast:Sucess'));
      return content;
    },

    async forgotPassword(newUser) {
      const content = await service.forgotPassword(newUser);

      if (content || content === '') toast.success(i18n.t('toast:Sucess'));
      return content;
    },

    async resetPassword(newUser) {
      const content = await service.resetPassword(newUser);

      if (content || content === '') toast.success(i18n.t('toast:Sucess'));
      return content;
    },

    confirmAccount: service.confirmAccount,
    delete: service.deleteUser
  }
};

export default user;

import { api, secureCall } from 'utils/api';

export const getJobs = secureCall((entityId) => api.get(`entities/${entityId}/jobs`));

export const createJob = secureCall(({ entityId, payload }) => api.post(`entities/${entityId}/jobs`, payload));

export const updateJob = secureCall(({ entityId, payload }) =>
  api.patch(`entities/${entityId}/jobs/${payload.jobId}`, payload)
);

export const deleteJob = secureCall(({ entityId, jobId }) =>
  api.delete(`entities/${entityId}/jobs/${jobId}`, { data: { entityId, jobId } })
);

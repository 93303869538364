import styled from '@emotion/styled';
import { cover } from 'polished';

import { Link, Picture } from 'components/atoms';

export default {
  Header: styled.header`
    ${(p) => `
      box-shadow: ${p.theme.shadow};
      background: ${p.theme.colors.background};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${p.theme.space[8]} 0;
    `}
  `,
  Picture: styled(Picture)`
    pointer-events: none;
    ${cover()}
  `,
  CustomLink: styled(Link)`
    &:active,
    &:hover {
      &:after {
        display: none;
      }
    }
  `
};

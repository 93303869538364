import { useEffect } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const ConfirmAccount = ({ confirmAccount }) => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const confirm = async () => {
      const success = await confirmAccount(decodeURIComponent(token));
      if (success) navigate('/');
    };
    confirm();
  }, [confirmAccount, token, navigate]);

  return '';
};

ConfirmAccount.propTypes = {
  confirmAccount: func.isRequired
};

const mapDispatch = (dispatch) => ({
  confirmAccount: dispatch.user.confirmAccount
});

export default connect(null, mapDispatch)(ConfirmAccount);

/* eslint-disable complexity */
import styled from '@emotion/styled';
import { cover, lighten, transparentize } from 'polished';
import { border, compose, get, layout, space, system, typography, variant } from 'styled-system';
import { isNil } from 'ramda';

import { elementSize } from 'components/helpers';

const getVariants = (bare, styles) => {
  let variants = {};
  variants = styles.reduce(
    (stack, style) => ({
      ...stack,
      [style.name]: {
        color: bare && style.bare ? style.bare.color : style.classic.color,
        bg: bare && style.bare ? 'transparent' : style.classic.bg,
        textDecoration: bare && style.bare ? 'underline' : 'none',
        '&:hover': {
          bg: bare && style.bare ? style.bare.hoverBg : style.classic.hoverBg,
          textDecoration: bare && style.bare ? style.bare.hoverTD : style.classic.hoverTD
        }
      }
    }),
    {}
  );
  return variants;
};

export default {
  SpinnerContainer: styled.span`
    ${cover()}
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Button: styled.button`
    ${(p) => `
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      text-align: center;
      line-height: 1.5;
      font-weight: ${p.theme.fontWeights[1]};
      border: none;
      outline: none;
      overflow: hidden;
      position: relative;
      text-decoration: none;
      transition: background-color ${p.theme.transition}, color ${p.theme.transition};
      border-radius: ${p.theme.radii[1]};
    `}
    ${(p) =>
      p.disabled
        ? `
          opacity: .6;
          pointer-events: none;
        `
        : 'cursor: pointer;'}
    ${(p) =>
      system({
        elementSize: {
          scale: 'elementSizes',
          property: 'minWidth',
          transform: (val, scale) => (p.iconOnly ? get(scale, val) : 80)
        }
      })}
    ${(p) =>
      system({
        elementSize: {
          scale: 'space',
          property: 'padding',
          transform: (val, scale) => {
            if (isNil(val)) return null;
            return p.iconOnly ? 0 : `2px ${get(scale, parseInt(val, 10) + 2)}`;
          }
        }
      })}
    ${system({
      elementSize: {
        scale: 'fontSizes',
        property: 'fontSize',
        transform: (val, scale) => get(scale, parseInt(val, 10) + 1)
      }
    })}
    ${(p) =>
      variant({
        variants: getVariants(p.bare, [
          {
            name: 'primary',
            classic: {
              color: 'dark',
              bg: 'yellow',
              hoverBg: lighten(0.1, p.theme.colors.yellow)
            },
            bare: {
              color: 'dark',
              hoverBg: transparentize(0.8, p.theme.colors.yellow)
            }
          },
          {
            name: 'secondary',
            classic: {
              color: 'dark',
              bg: p.theme.colors.yellow,
              hoverBg: p.theme.colors.yellow
            },
            bare: {
              color: 'dark',
              hoverBg: p.theme.colors.yellow
            }
          },
          {
            name: 'tertiary',
            classic: {
              color: 'dark',
              bg: 'transparent',
              hoverBg: 'transparent',
              hoverTD: 'underline'
            },
            bare: {
              color: 'dark',
              bg: 'transparent',
              hoverBg: 'transparent',
              hoverTD: 'underline'
            }
          }
        ])
      })}
    ${compose(elementSize(['minHeight']), border, space, layout, typography)}
  `
};

import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { Text } from 'components/atoms';

import { object } from 'prop-types';

const BonusParagraph = ({ bonus }) => {
  const { t } = useTranslation();

  const bonusParagraph = useMemo(
    () => [
      t('home:paragraph4'),
      t('home:paragraph4.1', { trimestersPrice: bonus?.trimestersPrice || '' }),
      t('home:paragraph4.2', { trimestersPrice: bonus?.trimestersPrice || '' }),
      t('home:paragraph4.3', { trimestersPrice: bonus?.trimestersPrice || '' }),
      t('home:paragraph4.4', { trimestersPrice: bonus?.trimestersPrice || '' }),
      t('home:paragraph4.5')
    ],
    [bonus, t]
  );

  return (
    <>
      {bonusParagraph.map((paragraph) => (
        <Text fontSize={3} mb={2} textAlign="center">
          {paragraph}
        </Text>
      ))}
    </>
  );
};

BonusParagraph.propTypes = {
  bonus: object.isRequired
};

export default BonusParagraph;

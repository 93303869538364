import { Box } from 'components/atoms';
import { array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const ChartBar = ({ data }) => {
  const { t } = useTranslation('dashboard');

  const maxLength = 8;
  const dataFormated = data.map((d) => ({
    ...d,
    entity: `${d.entity?.substring(0, maxLength)}${d.entity?.length > maxLength ? '...' : ''}`
  }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={500} height={280} data={dataFormated}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="entity" />
        <YAxis />
        <Tooltip
          content={({ payload }) => {
            if (payload.length) {
              return (
                <Box p={2} bg="whitesmoke" borderRadius={5}>
                  <p>{`${t('perEntity')}: ${payload[0].value}`}</p>
                  <p>{`${t('interEntity')}: ${payload[1].value}`}</p>
                </Box>
              );
            }

            return null;
          }}
        />
        <Bar dataKey="perEntity" fill="#f15326" />
        <Bar dataKey="interEntity" fill="#fdbf37" />
      </BarChart>
    </ResponsiveContainer>
  );
};

ChartBar.propTypes = {
  data: array.isRequired
};

export default ChartBar;

import styled from '@emotion/styled';
import { color, flexbox, fontStyle, layout, space, system, typography, variant } from 'styled-system';
import Text from '../Text/Text';

export default {
  Heading: styled(Text)`
    ${(p) =>
      variant({
        variants: {
          h1: {
            fontFamily: p.theme.fonts.ExconBold
          }
        }
      })}
    ${system({
      textTransform: true
    })}
    ${layout}
    ${fontStyle}
    ${flexbox}
    ${typography}
    ${space}
    ${color}
  `
};

import { bool, func } from 'prop-types';
import { pathOr } from 'ramda';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Card, Heading, Row } from 'components/atoms';
import { UserShortForm } from 'components/organisms';

const Login = ({ isLoading, login }) => {
  const { t } = useTranslation('auth');

  return (
    <Row justifyContent="center">
      <Box p="4" maxWidth="400px" width="100%">
        <Card p="5" bg="background" boxShadow={0} borderWidth={1} borderStyle="solid" borderColor="dark">
          <Heading mb="2">{t('auth:login')}</Heading>
          <UserShortForm onSubmit={login} submitting={isLoading} type="login" />
        </Card>
      </Box>
    </Row>
  );
};

Login.propTypes = {
  isLoading: bool.isRequired,
  login: func.isRequired
};

const mapState = ({ loading }) => ({
  isLoading: pathOr(false, ['effects', 'user', 'login'], loading)
});

const mapDispatch = ({ user: { login } }) => ({ login });

export default connect(mapState, mapDispatch)(Login);

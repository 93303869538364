import { toast } from 'react-toastify';

import { api, secureCall } from 'utils/api';

const ping = {
  state: {},
  reducers: {},
  effects: {
    async ping() {
      const content = await secureCall(() => api.get('ping'))();
      if (content) toast.success(content);
    }
  }
};

export default ping;

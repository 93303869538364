import { useRef, useState } from 'react';
import { array, func, node, number, object, oneOfType, string } from 'prop-types';

import { isVisible } from 'components/helpers';
import { Dropdown } from 'components/molecules';

const DropdownToggle = ({ renderElement, ...props }) => {
  const elementRef = useRef();
  const [showDD, setShowDD] = useState(false);
  return (
    <>
      {renderElement({
        elementRef,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowDD((show) => !show);
        },
        onKeyDown: (e) => {
          if (e.key === 'Enter') e.preventDefault();
        },
        onKeyUp: (e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            setShowDD((show) => !show);
          }
        },
        isOpen: showDD
      })}
      {showDD && (
        <Dropdown
          attachEl={elementRef}
          onClick={(e) => {
            e.stopPropagation();
            setShowDD(false);
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              setShowDD(false);
            }
          }}
          onRender={() => {
            if (!isVisible(elementRef.current) && showDD) {
              setShowDD(false);
            }
          }}
          onClickOutside={
            /* istanbul ignore next */
            (e) => {
              if (!elementRef.current.contains(e.target)) {
                setShowDD(false);
              }
            }
          }
          focusTrapOptions={{ clickOutsideDeactivates: true }}
          {...props}
        />
      )}
    </>
  );
};

DropdownToggle.propTypes = {
  /** element the dropdown is attached to, be careful to link elementRef to the ref of your component */
  renderElement: func.isRequired,
  /** styled-system width prop, dropdown will fit the attachEl if not defined */
  width: oneOfType([number, string, array, object]),
  /** dropdown content */
  children: node
};

DropdownToggle.defaultProps = {
  width: 'auto',
  children: null
};

export default DropdownToggle;

import { useTranslation } from 'react-i18next';
import { func, object } from 'prop-types';
import { useForm } from 'react-hook-form';
import { getError } from 'components/helpers';
import { Field } from 'components/molecules';

const JobForm = ({ onSubmit, job }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: job || {}
  });

  const validators = {
    name: {
      required: 'required'
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="jobForm">
      <input type="hidden" />
      <Field
        {...register('name', validators.name)}
        type="text"
        label={`${t('general:name')} *`}
        placeholder={t('general:name')}
        error={t(getError(['name', 'message'], errors))}
      />
      <Field
        {...register('description')}
        type="text"
        label={`${t('general:description')}`}
        placeholder={t('general:description')}
        error={t(getError(['description', 'message'], errors))}
      />
    </form>
  );
};

JobForm.propTypes = {
  onSubmit: func.isRequired,
  job: object
};

JobForm.defaultProps = {
  job: null
};

export default JobForm;

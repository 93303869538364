import { useContext, useEffect } from 'react';
import { array, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from 'utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { UserContext } from 'context';

import { Box, Button, Icon, Item, Link, Picture, Row } from 'components/atoms';
import { DropdownToggle } from 'components/molecules';
import { Translate } from 'components/organisms';
import Logo from 'assets/images/logo.svg';
import Styled from './Header.styled';

const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

const Header = ({ countries, getCountries, logout, updateUser }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['auth', 'home', 'user', 'general']);
  useEffect(() => getCountries(), [getCountries]);

  return (
    <Styled.Header>
      <Row
        gutter={8}
        flexDirection={['column', null, 'row']}
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        maxWidth="1400px">
        <Box px={[64, null, null, null, 8]}>
          <Row alignItems="center" flexDirection={['column', null, 'row']}>
            <Styled.CustomLink to="/">
              <Box
                width={[75, null, null, 150]}
                height={[23, null, null, 46]}
                mb={[0, null, '-5px', -12]}
                mr={[30, null, null, 50]}
                position="relative">
                <Box
                  width={[25, null, null, 50]}
                  height={[25, null, null, 50]}
                  backgroundColor="yellow"
                  borderRadius={30}
                  position="absolute"
                  top={[-4, null, null, -7]}
                />
                <Picture src={Logo} width={1} ml={[14, null, null, 28]} />
              </Box>
            </Styled.CustomLink>

            <Link to="/cooptation" textDecoration="none" mx={2}>
              {t('general:menu.cooptation')}
            </Link>
            <Link to="/cooptations" textDecoration="none" mx={2}>
              {t('general:menu.listing')}
            </Link>
            {user.isAdmin() && (
              <>
                <Link to="/dashboard" textDecoration="none" mx={2}>
                  {t('general:menu.dashboard')}
                </Link>
                <Link to="/admin/users" textDecoration="none" mx={2}>
                  {t('general:menu.admin')}
                </Link>
              </>
            )}
          </Row>
        </Box>
        <Box px={[64, null, null, null, 8]}>
          <Row>
            <Box>
              <Translate />
            </Box>
            <DropdownToggle
              width="150px"
              renderElement={({ elementRef, ...props }) => (
                <Button
                  variant="secondary"
                  iconOnly
                  borderRadius="50%"
                  ml={1}
                  bare={!!user.pictureUrl}
                  ref={elementRef}
                  {...props}>
                  <Styled.Picture
                    src={user.pictureUrl}
                    size="100%"
                    backgroundColor="yellow"
                    renderIcon={() => user.getUserInitial()}
                  />
                </Button>
              )}>
              <ul>
                <Item
                  onClick={() => {
                    if (location.pathname !== '/profile') {
                      navigate('/profile');
                    }
                  }}>
                  <Icon name="MdPerson" mr={2} /> {t('user:profile')}
                </Item>
                <Item
                  onClick={() => {
                    window.open(`mailto:${contactEmail}`, '_blank');
                  }}>
                  <Icon name="MdMail" mr={2} /> {t('general:contactUs')}
                </Item>
                <Item onClick={() => logout()} onKeyUp={(e) => e.key === 'Enter' && logout()}>
                  <Icon name="MdPowerSettingsNew" mr={2} /> {t('auth:logout')}
                </Item>
              </ul>
            </DropdownToggle>
            {user.role === USER_ROLES.ADMIN && (
              <Box pl="10px">
                <DropdownToggle
                  width="150px"
                  renderElement={({ elementRef, ...props }) => (
                    <Button variant="tertiary" iconOnly borderRadius="50%" ml={1} ref={elementRef} {...props}>
                      <Styled.Picture
                        size="100%"
                        renderIcon={() => (user?.country?.name || '').slice(0, 2).toUpperCase()}
                      />
                    </Button>
                  )}>
                  {countries.map((country) => (
                    <ul key={country.name}>
                      <Item
                        onClick={async () => {
                          if (country.countryId !== user.countryId) {
                            await updateUser({ countryId: country.countryId, userId: user.userId });
                            window.location.reload(false);
                          }
                        }}>
                        {country.name}
                      </Item>
                    </ul>
                  ))}
                </DropdownToggle>
              </Box>
            )}
          </Row>
        </Box>
      </Row>
    </Styled.Header>
  );
};

Header.propTypes = {
  logout: func.isRequired,
  updateUser: func.isRequired,
  getCountries: func.isRequired,
  countries: array.isRequired
};

const mapState = (state) => ({
  countries: state.country.list || []
});

const mapDispatch = ({ country, user }) => ({
  updateUser: user.update,
  getCountries: country.getCountries
});

export default connect(mapState, mapDispatch)(Header);

import { forwardRef } from 'react';
import { func, node, object, oneOfType, string } from 'prop-types';

import Styled from './Heading.styled';

const Heading = forwardRef((props, ref) => <Styled.Heading ref={ref} {...props} />);

Heading.propTypes = {
  /** element to render as */
  as: oneOfType([string, object, func]),
  variant: string,
  children: node
};

Heading.defaultProps = {
  as: 'h1',
  variant: 'h1',
  children: null
};

export default Heading;

import { USER_ROLES } from 'utils/constants';
import { entities } from './entity';
import { countries } from './country';

export const login = {
  id: '0',
  token: 'anEncryptedToken'
};

export const adminUser = {
  userId: login.id,
  email: 'h@cbtw.tech',
  firstName: 'Roméo',
  lastName: 'Van Laeken',
  role: USER_ROLES.ADMIN,
  isBanned: 0,
  isEmailConfirmed: 1,
  entity: entities[1],
  country: countries[0],
  countryId: countries[0].countryId
};

export const user = {
  userId: '1',
  email: 'jvanlaeken@positivethinking.tech',
  firstName: 'John',
  lastName: 'Van Laeken',
  role: USER_ROLES.USER,
  isBanned: 0,
  isEmailConfirmed: 1,
  entity: entities[3],
  country: countries[0],
  countryId: countries[0].countryId
};

export const external = {
  firstName: 'Enzo',
  lastName: 'Collet',
  email: 'enzo@mail.local',
  role: USER_ROLES.USER,
  entity: entities[3],
  country: countries[0],
  countryId: countries[0].countryId
};

export const hr1 = {
  firstName: 'Marie',
  lastName: 'Brioude',
  role: USER_ROLES.ADMIN,
  entity: entities[1],
  country: countries[0],
  countryId: countries[0].countryId
};

export const hr2 = {
  firstName: 'Margot',
  lastName: 'Wavré',
  role: USER_ROLES.ADMIN,
  entity: entities[1],
  country: countries[0],
  countryId: countries[0].countryId
};

import { Tabs } from 'components/molecules';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { string } from 'prop-types';
import { UserContext } from 'context';

const AdminMenu = ({ selected }) => {
  const { t } = useTranslation();
  const { role } = useContext(UserContext);
  const [menuItems, setMenuItems] = useState(['users', 'jobs', 'entities', 'bonus']);
  const navigate = useNavigate();

  useEffect(() => {
    if (role === 8) setMenuItems(['users', 'jobs', 'entities', 'bonus', 'countries']);
    else setMenuItems(['users', 'jobs', 'entities', 'bonus']);
  }, [role]);

  const onChange = (itemSelected) => {
    navigate(`/admin/${itemSelected}`);
  };

  return (
    <Tabs states={menuItems} getTabLabel={(state) => t(`general:menu.${state}`)} onChange={onChange} value={selected} />
  );
};

AdminMenu.propTypes = {
  selected: string
};

AdminMenu.defaultProps = {
  selected: 'users'
};

export default AdminMenu;

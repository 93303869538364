import styled from '@emotion/styled';
import { flexbox, layout, space } from 'styled-system';

export default {
  Container: styled.div`
    overflow: auto;
    ${flexbox}
    ${layout}
    ${space}
  `,
  List: styled.ul`
    display: flex;
    align-items: flex-end;
  `
};

import styled from '@emotion/styled';
import { border, compose, layout, space } from 'styled-system';
import { cover, darken, hideVisually } from 'polished';

import { Picture } from 'components/atoms';

export default {
  UploadFile: styled.label`
    ${(p) => `
      display: block;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      border: ${p.theme.borderWidths[1]} solid transparent;
    `}
    ${(p) => p.focus && `border-color: ${darken(0.1, p.theme.colors.yellow)};`}
    ${(p) => (p.readOnly || p.disabled) && 'pointer-events: none;'}
    ${compose(layout, space, border)}
  `,
  Input: styled.input`
    ${hideVisually()}
  `,
  Picture: styled(Picture)`
    width: 100%;
    height: 100%;
    ${(p) =>
      !p.hasFile() &&
      `
        label:hover > & {
          opacity: 0;
        }
      `}
    ${(p) => p.focus && !p.hasFile() && 'opacity: 0;'}
  `,
  Placeholder: styled.span`
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(p) =>
      p.hasFile()
        ? `
      color: ${p.theme.colors.light};
      background: ${p.theme.colors.blanket};
    `
        : `
      color: ${p.theme.colors.dark};
      background: ${p.theme.colors.yellow};
    `}
    ${cover()}
    label:hover > & {
      opacity: 1;
    }
    ${(p) => p.focus && !p.hasFile() && 'opacity: 1;'}
    ${border}
  `
};

import * as service from './service';

const cooptation = {
  state: {
    list: [],
    listGlobalCount: 0
  },
  reducers: {
    listUpdated(state, payload) {
      return {
        ...state,
        list: payload.rows || payload,
        listGlobalCount: payload.count || payload.length
      };
    }
  },
  effects: {
    async getCooptations(criteria) {
      const list = await service.getCooptations(criteria);
      if (list) this.listUpdated(list);
      return list;
    },
    createCooptation: service.createCooptation,
    signFile: service.getSignedUrl,
    editCooptation: service.editCooptation,
    deleteCooptation: service.deleteCooptation
  }
};

export default cooptation;

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { array, bool, func, number, object } from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { UserContext } from 'context';

import { getError } from 'components/helpers';
import { Box, Button, Link, Row } from 'components/atoms';
import { Field, Select } from 'components/molecules';

import { USER_GROUPS, USER_ROLES } from 'utils/constants';
import validations from 'validations/user';
import { connect } from 'react-redux';
import { renderOnCondition } from 'utils/component';

const buildLabel = (text, isRequired) => `${text}${isRequired ? ' *' : ''}`;

const UserForm = ({ onSubmit, submitting, user, hideActions, entities, getEntities, getCountries, countries }) => {
  const { userId, role } = useContext(UserContext);
  const { t } = useTranslation(['auth', 'user', 'validation', 'general']);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: user || {}
  });

  const passwordRequired = useMemo(() => !user, [user]);
  const validators = useMemo(
    () =>
      validations(
        getValues,
        passwordRequired,
        entities.map((e) => e.emailDomain)
      ),
    [getValues, entities, passwordRequired]
  );

  const [country, setCountry] = useState(user?.country);

  const setEntity = useCallback(
    (email) => {
      const entity = entities.find((e) => email.endsWith(e.emailDomain));
      setValue('entity', entity);
    },
    [setValue, entities]
  );

  useEffect(() => {
    if (countries.length) {
      setCountry(countries[0]);
    }
  }, [countries]);

  useEffect(() => {
    if (!country?.countryId) {
      getCountries();
    } else {
      getEntities({ where: { countryId: country.countryId } });
    }
  }, [getEntities, getCountries, country]);

  const submit = (newUser) => {
    onSubmit({ ...newUser, entityId: newUser?.entity?.entityId, countryId: country?.countryId });
  };

  return (
    <form onSubmit={handleSubmit(submit)} id="userForm">
      <input {...register('userId')} type="hidden" />
      <Field
        {...register('firstName', validators.firstName)}
        type="text"
        placeholder={t('user:firstNamePlaceholder')}
        label={`${t('user:firstName')} *`}
        error={t(getError(['firstName', 'message'], errors))}
      />
      <Field
        {...register('lastName', validators.lastName)}
        type="text"
        placeholder={t('user:lastNamePlaceholder')}
        label={`${t('user:lastName')} *`}
        error={t(getError(['lastName', 'message'], errors))}
      />
      <Field
        {...register('email', validators.emailCorporate)}
        type="email"
        autoComplete="email"
        placeholder="example@example.com"
        label={`${t('user:email')} *`}
        onValueChange={setEntity}
        error={t(getError(['email', 'message'], errors))}
      />
      {renderOnCondition(!user, () => (
        <Controller
          control={control}
          name="country"
          rules={validators.country}
          render={({ field: { onChange, onBlur } }) => (
            <Select
              label="Pays *"
              options={countries}
              searchable
              getOptionLabel={(e) => e?.name}
              error={t(getError(['entity', 'message'], errors))}
              onChange={(countrySelected) => {
                setCountry(countrySelected);
                onChange(countrySelected);
              }}
              onBlur={onBlur}
              value={country}
            />
          )}
        />
      ))}
      <Controller
        control={control}
        name="entity"
        rules={validators.entity}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            label={`${t('user:entity')} *`}
            options={entities}
            searchable
            defaultValue={[user?.entity]}
            getOptionLabel={(e) => e?.name}
            error={t(getError(['entity', 'message'], errors))}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      {renderOnCondition(userId === user?.userId, () => (
        <>
          <Field
            {...register('password', validators.password)}
            type="password"
            autoComplete="new-password"
            label={buildLabel(t('user:password'), passwordRequired)}
            error={t(getError(['password', 'message'], errors))}
          />
          <Field
            {...register('passwordConfirmation', validators.passwordConfirmation)}
            type="password"
            autoComplete="new-password"
            label={buildLabel(t('user:passwordConfirmation'), passwordRequired)}
            error={t(getError(['passwordConfirmation', 'message'], errors))}
          />
        </>
      ))}
      {renderOnCondition(
        USER_GROUPS.ADMIN.includes(role) && userId !== user.userId && user.role !== USER_ROLES.ADMIN,
        () => (
          <Controller
            control={control}
            name="role"
            rules={validators.role}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label={`${t('user:role')} *`}
                options={[2, 4]}
                searchable
                getOptionLabel={(e) => t(`user:roles.${e}`)}
                error={t(getError(['role', 'message'], errors))}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        )
      )}
      {!hideActions && (
        <Row alignItems="center" gutter="2">
          <Box px="2">
            <Button name="register" type="submit" variant="primary" isLoading={submitting}>
              {t(user ? 'general:save' : 'auth:register')}
            </Button>
          </Box>
          {!user && (
            <Box px="2">
              <Link to="/login">{t('auth:login')}</Link>
            </Box>
          )}
        </Row>
      )}
    </form>
  );
};

UserForm.propTypes = {
  onSubmit: func.isRequired,
  submitting: bool,
  type: number,
  user: object,
  hideActions: bool,
  getEntities: func.isRequired,
  getCountries: func.isRequired,
  entities: array.isRequired,
  countries: array.isRequired
};

UserForm.defaultProps = {
  user: null,
  hideActions: false,
  submitting: false,
  type: USER_ROLES.USER
};

const mapState = (state) => ({
  entities: state.entity.list || [],
  countries: state.country.list || []
});

const mapDispatch = ({ country, entity }) => ({
  getEntities: entity.getEntities,
  getCountries: country.getCountries
});

export default connect(mapState, mapDispatch)(UserForm);

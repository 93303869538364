import Styled from './AnimateCircle.styled';

const AnimateCircle = () => {
  return (
    <Styled.Container>
      <Styled.Circle1 />
      <Styled.Circle2 />
      <Styled.Circle3 />
      <Styled.Circle4 />
      <Styled.Circle5 />
      <Styled.Circle6 />
    </Styled.Container>
  );
};

export default AnimateCircle;

import { api, secureCall } from 'utils/api';

const route = 'cooptations';

function payloadToFormData(payload) {
  const formData = new FormData();
  Object.keys(payload).map((key) => formData.append(key, payload[key]));
  return formData;
}

export const getCooptations = secureCall((params) => api.get(route, { params }));
export const getCooptation = secureCall((id) => api.get(`${route}/${id}`));
export const getSignedUrl = secureCall((file) => api.get(`${route}/sign-file/${file}`));
export const createCooptation = secureCall((payload) => api.post(route, payloadToFormData(payload)));
export const editCooptation = secureCall((payload) =>
  api.patch(`${route}/${payload.cooptedId}`, payloadToFormData(payload))
);
export const deleteCooptation = secureCall((id) => api.delete(`${route}/${id}`));

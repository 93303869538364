import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { pathOr } from 'ramda';

import { Box, Card, Heading, Row } from 'components/atoms';
import { UserShortForm } from 'components/organisms';

const UpdatePassword = ({ isLoading, resetPassword }) => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const { token } = useParams();

  const reset = useCallback(
    async (data) => {
      const success = await resetPassword(data);
      if (success || success === '') navigate('/');
    },
    [resetPassword, navigate]
  );

  return (
    <Row justifyContent="center">
      <Box p="4" maxWidth="400px" width="100%">
        <Card p="5">
          <Heading mb="2">{t('auth:resetPassword')}</Heading>
          <UserShortForm
            onSubmit={(data) => reset({ ...data, token: decodeURIComponent(token) })}
            submitting={isLoading}
            type="updatePassword"
          />
        </Card>
      </Box>
    </Row>
  );
};

UpdatePassword.propTypes = {
  isLoading: bool.isRequired,
  resetPassword: func.isRequired
};

const mapState = ({ loading }) => ({
  isLoading: pathOr(false, ['effects', 'user', 'resetPassword'], loading)
});

const mapDispatch = (dispatch) => ({
  resetPassword: dispatch.user.resetPassword
});

export default connect(mapState, mapDispatch)(UpdatePassword);

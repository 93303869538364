import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

import { Box, Card, Heading, Row } from 'components/atoms';
import { CooptationForm } from 'components/organisms';

const Cooptation = ({ isLoading, createCooptation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (cooptation) => {
    if (
      await createCooptation({
        ...cooptation,
        ...(cooptation?.entityCooptor?.entityId && { entityCooptorId: cooptation?.entityCooptor?.entityId })
      })
    ) {
      toast.success(t('general:created'));
      navigate('/');
    }
  };

  return (
    <Row justifyContent="center">
      <Box p="4" maxWidth="400px" width="100%">
        <Card bg="background" boxShadow={0} borderWidth={1} borderStyle="solid" borderColor="dark">
          <Row justifyContent="space-between" alignItems="center">
            <Heading mb="2">{t('user:Cooptation')}</Heading>
          </Row>
          <CooptationForm onSubmit={onSubmit} submitting={isLoading} />
        </Card>
      </Box>
    </Row>
  );
};

Cooptation.propTypes = {
  isLoading: bool.isRequired,
  createCooptation: func.isRequired
};

const mapState = ({ loading }) => ({
  isLoading: loading?.effects?.cooptation?.createCooptation || false
});

const mapDispatch = (dispatch) => ({
  createCooptation: dispatch.cooptation.createCooptation
});

export default connect(mapState, mapDispatch)(Cooptation);

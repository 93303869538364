import { api, secureCall } from 'utils/api';

const route = 'country';

export const getCountries = secureCall((params) => api.get(route, { params }));

export const createCountry = secureCall((payload) => api.post(route, payload));

export const updateCountry = secureCall((payload) => api.patch(`${route}/${payload.countryId}`, payload));

export const deleteCountry = secureCall((id) => api.delete(`${route}/${id}`, { data: {} }));

export const USER_ROLES = {
  ADMIN: 8,
  HR: 4,
  USER: 2
};

export const USER_GROUPS = {
  ADMIN: [USER_ROLES.ADMIN, USER_ROLES.HR],
  USER: [USER_ROLES.USER, USER_ROLES.HR, USER_ROLES.ADMIN]
};

export const REGEXES = {
  EMAIL:
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x20-\x5b\x5d-\x7f]|\\[\x20-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x20-\x5a\x53-\x7f]|\\[\x20-\x7f])+)\])$/i,
  NAME: /^[A-Za-zÀ-ÖØ-öø-ÿ-.' ]+$/,
  PHONE: /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*[0-9]+$/,
  URL: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
};

export const DEFAULT_MAX_LENGTH = 256;
export const PASSWORD_MIN_LENGTH = 12;

import { forwardRef, useLayoutEffect, useState } from 'react';
import { bool, func, node, object, oneOfType, string } from 'prop-types';

import Spinner from '../Spinner/Spinner';
import Text from '../Text/Text';
import Box from '../Box/Box';
import Styled from './NoResult.styled';

const NoResult = forwardRef(({ isLoading, children, ...props }, ref) => {
  const [showSpinner, setShowSpinner] = useState();

  useLayoutEffect(() => {
    // skip a render to wait for dom operations to complete
    setShowSpinner(isLoading);
  }, [isLoading]);

  return (
    <Styled.NoResult ref={ref} {...props}>
      <Box as="span" position="relative">
        {(showSpinner || isLoading) && (
          <Styled.Cover>
            <Spinner />
          </Styled.Cover>
        )}
        <Text as="span" fontStyle="italic" color="grays.0" opacity={showSpinner || isLoading ? 0 : 1}>
          {children}
        </Text>
      </Box>
    </Styled.NoResult>
  );
});

NoResult.propTypes = {
  /** element to render as */
  as: oneOfType([string, object, func]),
  /** is loading */
  isLoading: bool,
  children: node
};

NoResult.defaultProps = {
  as: 'p',
  isLoading: false,
  children: null
};

export default NoResult;

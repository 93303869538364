import { forwardRef } from 'react';
import { array, bool, func, node, number, object, oneOfType, string } from 'prop-types';

import Styled from './Item.styled';

const Item = forwardRef(({ onClick, onKeyDown, ...props }, ref) => (
  <Styled.Item
    role="menuitem"
    tabIndex="0"
    ref={ref}
    onClick={onClick}
    onKeyDown={(event) => {
      if (!onKeyDown) {
        return event.key === 'Enter' && onClick(event);
      }
      return onKeyDown(event);
    }}
    {...props}
  />
));

Item.propTypes = {
  onClick: func,
  /** if not defined and Enter is pressed, will trigger onClick */
  onKeyDown: func,
  /** styled-system px prop */
  px: oneOfType([number, string, array, object]),
  /** styled-system py prop */
  py: oneOfType([number, string, array, object]),
  /** styled-system alignItems prop */
  alignItems: oneOfType([number, string, array, object]),
  /** styled-system display prop */
  display: oneOfType([number, string, array, object]),
  /** custom styled-system prop based on theme.elementSizes */
  elementSize: oneOfType([number, string, array, object]),
  /** custom styled-system hover color prop */
  highlightColor: oneOfType([string, array, object]),
  /** custom styled-system hover background-color prop */
  highlightBg: oneOfType([string, array, object]),
  /** set highlight manually */
  highlight: bool,
  children: node
};

Item.defaultProps = {
  onClick: () => null,
  onKeyDown: null,
  px: 2,
  py: 1,
  alignItems: 'center',
  display: 'flex',
  elementSize: 1,
  highlightColor: 'dark',
  highlightBg: 'grays.2',
  highlight: false,
  children: null
};

export default Item;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, string } from 'prop-types';
import { useForm } from 'react-hook-form';

import { renderOnCondition } from 'utils/component';

import { getError } from 'components/helpers';
import { Box, Button, Link, Row } from 'components/atoms';
import { Field } from 'components/molecules';

import validations from 'validations/user';

const UserShortForm = ({ onSubmit, submitting, type }) => {
  const { t } = useTranslation(['general', 'auth', 'user']);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur'
  });

  const validators = useMemo(() => validations(getValues, true), [getValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderOnCondition(['forgotPassword', 'login'].includes(type), () => (
        <Field
          {...register('email', validators.email)}
          autoComplete="email"
          type="email"
          endIcon="MdPerson"
          placeholder="example@example.com"
          label={`${t('user:email')} *`}
          error={t(getError(['email', 'message'], errors))}
        />
      ))}
      {renderOnCondition(['updatePassword', 'login'].includes(type), () => (
        <Field
          {...register('password', validators.password)}
          type="password"
          label={`${t('user:password')} *`}
          id="passInput"
          autoComplete={type === 'login' ? 'current-password' : 'new-password'}
          mb={type === 'login' ? 1 : null}
          error={t(getError(['password', 'message'], errors))}
        />
      ))}
      {renderOnCondition(['updatePassword'].includes(type), () => (
        <Field
          {...register('passwordConfirmation', validators.passwordConfirmation)}
          type="password"
          label={`${t('user:passwordConfirmation')} *`}
          id="passConfInput"
          autoComplete="new-password"
          error={t(getError(['passwordConfirmation', 'message'], errors))}
        />
      ))}
      {renderOnCondition(['login'].includes(type), () => (
        <Row justifyContent="flex-end" mb="4">
          <Link to="/password/forgot">{t('auth:forgotPassword')}</Link>
        </Row>
      ))}
      <Row alignItems="center" gutter="2">
        <Box px="2">
          <Button name="confirm" type="submit" isLoading={submitting}>
            {t(type === 'login' ? 'auth:login' : 'general:confirm')}
          </Button>
        </Box>
        <Box px="2">
          <Link to={type === 'login' ? '/register' : '/login'}>
            {t(`auth:${type === 'login' ? 'register' : 'login'}`)}
          </Link>
        </Box>
      </Row>
    </form>
  );
};

UserShortForm.propTypes = {
  onSubmit: func.isRequired,
  submitting: bool,
  type: string.isRequired
};

UserShortForm.defaultProps = {
  submitting: false
};

export default UserShortForm;

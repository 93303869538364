import { DEFAULT_MAX_LENGTH } from 'utils/constants';

export default {
  text: {
    maxLength: {
      value: DEFAULT_MAX_LENGTH,
      message: 'maxLength'
    }
  }
};

import styled from '@emotion/styled';
import { color, compose, flexbox, layout, space } from 'styled-system';

import { Icon } from 'components/atoms';

export default {
  Container: styled.div`
    ${compose(flexbox, layout, space)}
  `,
  Table: styled.table`
    ${(p) => `
      width: 100%;
      border-collapse: collapse;
      transition: opacity ${p.theme.transition};
      border: 1px solid ${p.theme.colors.dark};
    `}
  `,
  Row: styled.tr`
    ${(p) => `
      height: ${p.theme.elementSizes[1]};
      border-top: 1px solid ${p.theme.colors.dark};
    `}

    ${(p) =>
      p.clickable &&
      `
      cursor: pointer;
      tbody &:hover {
        background: ${p.theme.colors.grays[2]};
      }
    `}
    ${(p) => p.isLoading && !p.noData && 'opacity: 0.5;'}
  `,
  Cell: styled.td`
    ${(p) => `
      text-align: left;
      font-weight: ${p.theme.fontWeights[0]};
      padding: ${p.theme.space[1]} ${p.theme.space[3]};
      height: ${p.theme.elementSizes[1]};
      color: ${p.theme.colors.dark};
    `}
    ${(p) =>
      p.as === 'th' &&
      `white-space: nowrap;background-color: ${p.theme.colors.dark}!important;color: ${p.theme.colors.light};`}
    ${(p) =>
      p.sticky &&
      `
      position: sticky;
      top: 0;
    `}
    ${compose(color, layout, space)}
  `,
  Arrow: styled(Icon)`
    ${(p) => `margin-left: ${p.theme.space[1]};`}
    ${(p) => (p.isSorted && `color: ${p.theme.colors.light};`) || `color: ${p.theme.colors.secondary};`}
  `
};

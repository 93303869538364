import * as service from './service';

const bonus = {
  state: {
    result: {}
  },
  reducers: {
    updateState(state, payload) {
      return {
        ...state,
        result: payload || {}
      };
    }
  },
  effects: {
    async getBonus(criteria) {
      const result = await service.getBonus(criteria);
      if (result) this.updateState(result);
      return result;
    },
    updateBonus: service.updateBonus
  }
};

export default bonus;

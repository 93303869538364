import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bool, func, object } from 'prop-types';

import { Box, Button, Icon, Row } from 'components/atoms';
import { Modal, Table } from 'components/molecules';
import { BonusForm } from 'components/organisms';
import { AdminMenu } from 'pages';

const BonusPage = ({ getItem, bonus, updateItem, isFetchingList, isUpdating }) => {
  const { t } = useTranslation();
  const [itemEdited, setItemEdited] = useState({});
  const [list, setList] = useState([]);
  const [currentAction, setCurrentAction] = useState(null);

  const columns = useMemo(
    () => [
      { Header: t('general:name'), accessor: 'label' },
      { Header: '', accessor: 'value' },
      {
        id: 'actions',
        cellProps: { width: '1px', p: 0 },
        Cell: () => (
          <Row flexWrap="nowrap" height="100%">
            <Button variant="secondary" bare iconOnly borderRadius="0">
              <Icon name="MdEdit" />
            </Button>
          </Row>
        )
      }
    ],
    [t]
  );

  const closeModal = async (item) => {
    if (bonus?.bonusId && item.value) {
      if (currentAction === 'edit') await updateItem({ [item.key]: item.value, bonusId: bonus.bonusId });

      await getItem();
    }
    setCurrentAction(null);
    setItemEdited(null);
  };

  useEffect(() => {
    getItem();
  }, [getItem]);

  useEffect(() => {
    if (typeof bonus === 'object') {
      setList(
        Object.keys(bonus)
          .filter((key) => key.includes(['trimestersPric']))
          .map((key) => ({ key, label: t(`bonus:${key}`), value: bonus[key] }))
      );
    }
  }, [bonus, t]);

  return (
    <Row justifyContent="center">
      <Box p="4" width="100%">
        <Row justifyContent="space-between" alignItems="center" mb="2">
          <Box py="12px">
            <AdminMenu selected="bonus" />
          </Box>
        </Row>
        <Table
          isLoading={isFetchingList}
          mb="5"
          tableParams={{
            initialState: {
              sortBy: [{ id: 'key', desc: false }]
            }
          }}
          getRowProps={({ original: item }) => ({
            onClick: () => {
              setItemEdited(item);
              setCurrentAction('edit');
            }
          })}
          data={list}
          columns={columns}
        />
      </Box>
      <Modal
        show={['create', 'edit'].includes(currentAction)}
        onClose={closeModal}
        title={t('general:edit')}
        actions={[
          (props) => <Button {...props} />,
          (props) => (
            <Button
              {...props}
              autoFocus
              variant="primary"
              form="bonusForm"
              type="submit"
              isLoading={!!(isUpdating || isFetchingList)}
              onClick={() => null}>
              {t('general:save')}
            </Button>
          )
        ]}>
        <BonusForm item={itemEdited} onSubmit={closeModal} />
      </Modal>
      <Modal
        show={currentAction === 'delete'}
        onClose={closeModal}
        title={t('general:delete')}
        actions={[
          (props) => <Button {...props} />,
          (props) => (
            <Button {...props} autoFocus variant="primary" onClick={() => closeModal(itemEdited)} isLoading={false}>
              {t('general:confirm')}
            </Button>
          )
        ]}>
        <Box my={2} as="p">
          {t('general:confirmDelete')}
        </Box>
      </Modal>
    </Row>
  );
};

BonusPage.propTypes = {
  getItem: func.isRequired,
  updateItem: func.isRequired,
  bonus: object.isRequired,
  isFetchingList: bool.isRequired,
  isUpdating: bool.isRequired
};

const mapState = ({ bonus, loading }) => ({
  bonus: bonus?.result || {},
  isFetchingList: loading?.effects?.bonus?.getBonus || false,
  isUpdating: loading?.effects?.bonus?.updateBonus || false
});

const mapDispatch = ({ bonus }) => ({
  getItem: bonus.getBonus,
  updateItem: bonus.updateBonus
});

export default connect(mapState, mapDispatch)(BonusPage);

import { omit } from 'ramda';
import { toast } from 'react-toastify';

import i18n from 'translate/i18n';

import { api, handleError, secureCall } from 'utils/api';
import { getStorage, setStorage } from 'utils/storage';

export const handleToken = (token, save = false) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
  return save ? setStorage('token', token) : true;
};

export const init = secureCall(
  (id) => api.get(`users/${id}`),
  (e) => {
    if ([404].includes(e?.response?.status)) toast.error(i18n.t('errors:authFailed'));
    else handleError(e);
    return null;
  }
);

export const initWithToken = async () => {
  const token = getStorage('token');
  const id = getStorage('id');
  if (token && id) {
    handleToken(token);
    const userData = await init(id);
    return userData;
  }
  return null;
};

export const register = secureCall((user) => api.post('users', user));

export const login = secureCall(async (payload) => {
  const { token, id } = (await api.post('auth/login', payload)) || {};
  if (token && id) {
    handleToken(token, true);
    const userData = await init(id);
    setStorage('id', id);
    return userData;
  }
  return null;
});

export const update = secureCall(async (id, user) => {
  await api.patch(`users/${id}`, user);
  return omit(['password', 'passwordConfirmation'], user);
});

export const handlePicture = secureCall(async (id, picture) => {
  const formData = new FormData();
  formData.append('picture', picture);
  const pictureUrl = await api.post(`users/${id}/picture`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return { pictureUrl };
});

export const forgotPassword = secureCall(async ({ email }) =>
  api.post('auth/forgot-password?template=/password/edit/:token', { email })
);

export const resetPassword = secureCall(async (payload) => {
  handleToken(payload.token);
  return api.post('auth/forgot-password/update', omit(['token'], payload));
});

export const confirmAccount = secureCall(async (token) =>
  api.post('auth/confirm-account', null, {
    Authorization: `Bearer ${token}`
  })
);

export const getUsers = secureCall((params) => api.get('users', { params }));

export const deleteUser = secureCall((id) => api.delete(`users/${id}`, { data: {} }));

import { USER_GROUPS } from 'utils/constants';

class UserEntity {
  constructor(user) {
    if (user) {
      Object.keys(user).forEach((key) => {
        this[key] = user[key];
      });
    }
  }

  getUserInitial() {
    if (this.firstName && this.lastName) {
      return this.firstName.slice(0, 1) + this.lastName.slice(0, 1);
    }
    return '';
  }

  isAdmin() {
    return USER_GROUPS.ADMIN.includes(this.role);
  }
}

export default UserEntity;

import * as service from './service';

const kpi = {
  state: {
    ratioCooptationsHired: [],
    countCooptationsByHr: [],
    countCooptationsByEntity: []
  },
  reducers: {
    updateState(state, key, object) {
      return {
        ...state,
        [key]: object
      };
    }
  },
  effects: {
    async getRatioCooptationsHired(criteria) {
      const result = await service.getRatioCooptationsHired(criteria);
      if (result) this.updateState('ratioCooptationsHired', result);
      return result;
    },
    async getCountCooptationsByHr(criteria) {
      const result = await service.getCountCooptationsByHr(criteria);
      if (result) this.updateState('countCooptationsByHr', result);
      return result;
    },
    async getCountCooptationsByEntity(criteria) {
      const result = await service.getCountCooptationsByEntity(criteria);
      if (result) this.updateState('countCooptationsByEntity', result);
      return result;
    }
  }
};

export default kpi;

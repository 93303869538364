import styled from '@emotion/styled';
import {
  compose,
  display,
  height,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  overflow,
  overflowX,
  overflowY,
  position,
  size,
  space,
  system,
  verticalAlign,
  width
} from 'styled-system';
import { hideVisually } from 'polished';

import { Card } from 'components/atoms';

const getCalculatedPosition = (isTopPart, isLeftPart, { top, left, right, bottom }) => ({
  ...(isTopPart
    ? { top: `${bottom}px` }
    : {
        top: `${top}px`,
        transform: !isLeftPart ? 'translate(-100%, -100%)' : 'translateY(-100%)'
      }),
  ...(isLeftPart
    ? { left: `${left}px` }
    : {
        left: `${right}px`,
        transform: !isTopPart ? 'translate(-100%, -100%)' : 'translateX(-100%)'
      })
});

export const getLayoutProps = (el, dd, automaticPosition, windowDimensions) => {
  // get position and width styles based on the location of the element the dropdown is attached to
  // below if the element has the space it needs, else above
  // stick to left if the element has the space it needs, else stick to right

  if (el && dd && automaticPosition) {
    const elRect = el.getBoundingClientRect();
    const { bottom, left, width: matchingWidth } = elRect;

    const { height: ddHeight, width: ddWidth } = dd.getBoundingClientRect();
    const isTopPart = bottom + ddHeight < windowDimensions.winHeight;
    const isLeftPart = left + ddWidth < windowDimensions.winWidth;

    return {
      ...getCalculatedPosition(isTopPart, isLeftPart, elRect),
      matchingWidth
    };
  }
  return {};
};

export default {
  Dropdown: styled.div`
    ${compose(
      display,
      height,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      overflow,
      overflowX,
      overflowY,
      position,
      size,
      space,
      verticalAlign,
      system({
        transform: true
      })
    )}
    ${(p) =>
      system({
        width: {
          property: 'width',
          transform: (val) => {
            if (val === 'fit') return p.matchingWidth;
            return width({ width: val, theme: p.theme }).width;
          }
        }
      })}
  `,
  Content: styled(Card)`
    ${(p) => `
      background-color: ${p.theme.colors.background};
      height: 100%;
      padding: 0;
      margin: ${p.theme.space[1]} 0;
    `}
  `,
  Close: styled.button`
    ${hideVisually()}
  `
};

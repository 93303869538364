import { Navigate, Route, Routes } from 'react-router-dom';
import { bool } from 'prop-types';

import { USER_GROUPS, USER_ROLES } from 'utils/constants';

import { AuthRoute } from 'components/atoms';
import {
  BonusPage,
  ConfirmAccount,
  Cooptation,
  Cooptations,
  Countries,
  Dashboard,
  Entities,
  Error,
  ForgotPassword,
  HomePage,
  Jobs,
  Login,
  Profile,
  Register,
  UpdatePassword,
  Users
} from 'pages';

const ProjectRoutes = ({ isLogged }) => (
  <Routes>
    <Route path="/error/:code" exact element={<Error />} />
    <Route
      path="/login"
      exact
      element={
        <AuthRoute offlineOnly>
          <Login />
        </AuthRoute>
      }
    />
    <Route
      path="/register"
      exact
      element={
        <AuthRoute offlineOnly>
          <Register />
        </AuthRoute>
      }
    />
    <Route
      path="/confirm/:token"
      exact
      element={
        <AuthRoute offlineOnly>
          <ConfirmAccount />
        </AuthRoute>
      }
    />
    <Route
      path="/password/forgot"
      exact
      element={
        <AuthRoute offlineOnly>
          <ForgotPassword />
        </AuthRoute>
      }
    />
    <Route
      path="/password/edit/:token"
      exact
      element={
        <AuthRoute offlineOnly>
          <UpdatePassword />
        </AuthRoute>
      }
    />
    <Route
      path="/"
      exact
      element={
        <AuthRoute group={USER_GROUPS.USER}>
          <HomePage />
        </AuthRoute>
      }
    />
    <Route
      path="/cooptation"
      exact
      element={
        <AuthRoute group={USER_GROUPS.USER}>
          <Cooptation />
        </AuthRoute>
      }
    />
    <Route
      path="/cooptations"
      exact
      element={
        <AuthRoute group={USER_GROUPS.USER}>
          <Cooptations />
        </AuthRoute>
      }
    />
    <Route
      path="/profile"
      exact
      element={
        <AuthRoute group={USER_GROUPS.USER}>
          <Profile />
        </AuthRoute>
      }
    />
    <Route
      path="/admin/users"
      exact
      element={
        <AuthRoute group={USER_GROUPS.ADMIN}>
          <Users />
        </AuthRoute>
      }
    />
    <Route
      path="/admin/jobs"
      exact
      element={
        <AuthRoute group={USER_GROUPS.ADMIN}>
          <Jobs />
        </AuthRoute>
      }
    />
    <Route
      path="/admin/entities"
      exact
      element={
        <AuthRoute group={USER_GROUPS.ADMIN}>
          <Entities />
        </AuthRoute>
      }
    />
    <Route
      path="/admin/countries"
      exact
      element={
        <AuthRoute group={[USER_ROLES.ADMIN]}>
          <Countries />
        </AuthRoute>
      }
    />
    <Route
      path="/admin/bonus"
      exact
      element={
        <AuthRoute group={USER_GROUPS.ADMIN}>
          <BonusPage />
        </AuthRoute>
      }
    />
    <Route
      path="/dashboard"
      exact
      element={
        <AuthRoute group={USER_GROUPS.ADMIN}>
          <Dashboard />
        </AuthRoute>
      }
    />
    <Route path="*" element={<Navigate to={isLogged ? '/error/404' : '/login'} replace />} />
  </Routes>
);

ProjectRoutes.propTypes = {
  isLogged: bool
};
ProjectRoutes.defaultProps = {
  isLogged: false
};

export default ProjectRoutes;

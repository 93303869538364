import { useTranslation } from 'react-i18next';
import { func, object } from 'prop-types';
import { useForm } from 'react-hook-form';
import { getError } from 'components/helpers';
import { Field } from 'components/molecules';

const BonusForm = ({ onSubmit, item }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    defaultValues: item || {}
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="bonusForm">
      <input type="hidden" />
      <Field
        {...register('value')}
        type="text"
        label={t(`bonus:${item.key}`)}
        placeholder={t(`bonus:${item.key}`)}
        error={t(getError(['value', 'message'], errors))}
      />
    </form>
  );
};

BonusForm.propTypes = {
  onSubmit: func.isRequired,
  item: object
};

BonusForm.defaultProps = {
  item: null
};

export default BonusForm;

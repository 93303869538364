import { useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { array, bool, func } from 'prop-types';
import { UserContext } from 'context';

import { pathOr } from 'ramda';

import { Box, Button, Icon, Row, Text } from 'components/atoms';
import { Modal, Select, Table } from 'components/molecules';
import { JobForm } from 'components/organisms';
import { AdminMenu } from 'pages';

const Jobs = ({
  getItems,
  createItem,
  updateItem,
  deleteItem,
  list,
  isFetchingList,
  isUpdating,
  isDeleting,
  entities,
  getEntities
}) => {
  const { t } = useTranslation();
  const currentUser = useContext(UserContext);
  const [itemEdited, setItemEdited] = useState({});
  const [currentAction, setCurrentAction] = useState(null);
  const [entity, setEntity] = useState({});

  const columns = useMemo(
    () => [
      { Header: t('general:name'), accessor: 'name' },
      { Header: t('general:description'), accessor: 'description' },
      {
        id: 'actions',
        cellProps: { width: '1px', p: 0 },
        Cell: (rowData) => (
          <Row flexWrap="nowrap" height="100%">
            <Button variant="secondary" bare iconOnly borderRadius="0">
              <Icon name="MdEdit" />
            </Button>
            <Button
              variant="secondary"
              bare
              iconOnly
              borderRadius="0"
              onClick={(e) => {
                e.stopPropagation();
                setItemEdited(pathOr({}, ['row', 'original'], rowData));
                setCurrentAction('delete');
              }}>
              <Icon name="MdDelete" />
            </Button>
          </Row>
        )
      }
    ],
    [t]
  );

  const closeModal = async (item) => {
    if (currentAction === 'create' && item?.name) {
      await createItem({ entityId: entity.entityId, payload: item });

      await getItems(entity.entityId);
    } else if (item?.jobId) {
      if (currentAction === 'edit') await updateItem({ entityId: entity.entityId, payload: item });
      else if (currentAction === 'delete') await deleteItem({ entityId: entity.entityId, jobId: item.jobId });

      await getItems(entity.entityId);
    }
    setCurrentAction(null);
    setItemEdited(null);
  };

  useEffect(() => {
    setEntity(entities[0]);
  }, [entities]);

  useEffect(() => getEntities({ where: { countryId: currentUser.countryId } }), [getEntities, currentUser]);

  useEffect(() => {
    if (entity?.entityId) {
      getItems([entity.entityId]);
    }
  }, [entity, getItems]);

  const onEntityChange = (entitySelected) => {
    setEntity(entitySelected);
  };

  return (
    <Row justifyContent="center">
      <Box p="4" width="100%">
        <Row justifyContent="space-between" alignItems="center" mb="2">
          <Box py="12px">
            <AdminMenu selected="jobs" />
          </Box>
          <Row>
            <Row alignItems="center">
              <Box mx="2">
                <Text>{t('user:entity')}:</Text>
              </Box>
              <Box>
                <Select
                  options={entities}
                  searchable
                  getOptionLabel={(e) => e?.name}
                  onChange={onEntityChange}
                  value={entity}
                  my="2"
                />
              </Box>
              <Button type="button" onClick={() => setCurrentAction('create')} width="35" max-height="60px" ml={3}>
                {t('general:create')}
              </Button>
            </Row>
          </Row>
        </Row>
        <Table
          isLoading={isFetchingList}
          mb="5"
          getRowProps={({ original: item }) => ({
            onClick: () => {
              setItemEdited(item);
              setCurrentAction('edit');
            }
          })}
          data={list}
          columns={columns}
        />
      </Box>
      <Modal
        show={['create', 'edit'].includes(currentAction)}
        onClose={closeModal}
        title={t('general:edit')}
        actions={[
          (props) => <Button {...props} />,
          (props) => (
            <Button
              {...props}
              autoFocus
              variant="primary"
              form="jobForm"
              type="submit"
              isLoading={!!(isUpdating || isFetchingList)}
              onClick={() => null}>
              {t('general:save')}
            </Button>
          )
        ]}>
        <JobForm job={itemEdited} onSubmit={closeModal} />
      </Modal>
      <Modal
        show={currentAction === 'delete'}
        onClose={closeModal}
        title={t('general:delete')}
        actions={[
          (props) => <Button {...props} />,
          (props) => (
            <Button
              {...props}
              autoFocus
              variant="primary"
              onClick={() => closeModal(itemEdited)}
              isLoading={!!(isDeleting || isFetchingList)}>
              {t('general:confirm')}
            </Button>
          )
        ]}>
        <Box my={2} as="p">
          {t('general:confirmDelete')}
        </Box>
      </Modal>
    </Row>
  );
};

Jobs.propTypes = {
  list: array.isRequired,
  getItems: func.isRequired,
  createItem: func.isRequired,
  updateItem: func.isRequired,
  deleteItem: func.isRequired,
  isFetchingList: bool.isRequired,
  isUpdating: bool.isRequired,
  isDeleting: bool.isRequired,
  entities: array.isRequired,
  getEntities: func.isRequired
};

const mapState = ({ job, entity, loading }) => ({
  list: job.list,
  isFetchingList: loading?.effects?.job?.getJobs || false,
  isUpdating: loading?.effects?.job?.updateJob || false,
  isDeleting: loading?.effects?.job?.deleteJob || false,
  entities: entity.list
});

const mapDispatch = ({ job, entity }) => ({
  getItems: job.getJobs,
  createItem: job.createJob,
  updateItem: job.updateJob,
  deleteItem: job.deleteJob,
  getEntities: entity.getEntities
});

export default connect(mapState, mapDispatch)(Jobs);

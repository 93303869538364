import { forwardRef } from 'react';
import { array, func, node, number, object, oneOfType, string } from 'prop-types';

import Styled from './Link.styled';

const Link = forwardRef(({ p, ...props }, ref) => <Styled.Link ref={ref} p={p} {...props} />);

Link.propTypes = {
  /** styled-system border prop */
  border: oneOfType([string, object, func]),
  /** styled-system background prop */
  background: oneOfType([string, object, func]),
  /** styled-system padding prop */
  p: oneOfType([number, string, object, func]),
  /** styled-system color prop */
  color: oneOfType([string, array, object]),
  /** custom styled-system text-decoration prop */
  textDecoration: oneOfType([string, array, object]),
  /** custom styled-system cursor prop */
  cursor: oneOfType([string, array, object]),
  children: node
};

Link.defaultProps = {
  border: 'none',
  background: 'none',
  p: 0,
  color: 'inherit',
  textDecoration: 'underline',
  cursor: 'pointer',
  children: null
};

export default Link;

import { useMemo } from 'react';
import { bool, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { renderOnCondition } from 'utils/component';

import { UserContext, UserEntity } from 'context';

import { Box, Loader } from 'components/atoms';
import { Header } from 'components/organisms';
import Routes from 'Routes';

const App = ({ user, isLogged, isInitialized, logout }) => {
  const userEntity = useMemo(() => new UserEntity(user), [user]);

  return isInitialized ? (
    <UserContext.Provider value={userEntity}>
      <Loader />
      <div>
        {renderOnCondition(isLogged, () => (
          <Header logout={logout} />
        ))}
        <Box>
          <Routes isLogged={isLogged} />
        </Box>
        <ToastContainer transition={Slide} />
      </div>
    </UserContext.Provider>
  ) : (
    <Loader isLoading />
  );
};

App.propTypes = {
  user: object,
  isLogged: bool.isRequired,
  isInitialized: bool.isRequired,
  logout: func.isRequired
};

App.defaultProps = {
  user: null
};

const mapState = ({ user: { isInitialized, isLogged, current } }) => ({
  isInitialized,
  isLogged,
  user: current
});

const mapDispatch = ({ user: { logout } }) => ({ logout });

export default connect(mapState, mapDispatch)(App);

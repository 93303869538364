import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Card, Heading, Row } from 'components/atoms';
import { UserForm } from 'components/organisms';

const Register = ({ isLoading, register }) => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const doRegister = useCallback(
    async (data) => {
      const success = await register(data);
      if (success || success === '') navigate('/');
    },
    [register, navigate]
  );

  return (
    <Row justifyContent="center">
      <Box p="4" maxWidth="400px" width="100%">
        <Card p="5" bg="background" boxShadow={0} borderWidth={1} borderStyle="solid" borderColor="dark">
          <Heading mb="2">{t('auth:register')}</Heading>
          <UserForm onSubmit={doRegister} submitting={isLoading} />
        </Card>
      </Box>
    </Row>
  );
};

Register.propTypes = {
  isLoading: bool.isRequired,
  register: func.isRequired
};

const mapState = ({ loading }) => ({
  isLoading: loading?.effects?.user?.update || false
});
const mapDispatch = (dispatch) => ({
  register: dispatch.user.register
});

export default connect(mapState, mapDispatch)(Register);

import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { array, func } from 'prop-types';

import { Box, Container, Heading, Row, Text } from 'components/atoms';
import { ChartBar, CircleChart } from 'components/organisms';
import { Field, Table } from 'components/molecules';

const Dashboard = ({
  ratioCooptationsHired,
  getRatioCooptationsHired,
  countCooptationsByHr,
  getCountCooptationsByHr,
  countCooptationsByEntity,
  getCountCooptationsByEntity
}) => {
  const { t } = useTranslation('dashboard');
  const currentDate = new Date();
  const { handleSubmit, control, register, getValues, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      startDate: new Date(new Date().setMonth(currentDate.getMonth() - 6)).toISOString().slice(0, 10),
      endDate: currentDate.toISOString().slice(0, 10)
    }
  });

  // INIT
  const startDate = useWatch({ control, name: 'startDate' });
  const endDate = useWatch({ control, name: 'endDate' });

  const columnsCooptedByHR = useMemo(
    () => [
      { Header: t('hr'), accessor: 'name' },
      { Header: t('managedCooptation'), accessor: 'value' }
    ],
    [t]
  );

  const columnsCountCooptationByEntity = useMemo(
    () => [
      { Header: t('entity'), accessor: 'entity' },
      { Header: t('perEntity'), accessor: 'perEntity' },
      { Header: t('interEntity'), accessor: 'interEntity' }
    ],
    [t]
  );

  useEffect(() => getRatioCooptationsHired({ startDate, endDate }), [getRatioCooptationsHired, startDate, endDate]);
  useEffect(() => getCountCooptationsByHr({ startDate, endDate }), [getCountCooptationsByHr, startDate, endDate]);
  useEffect(
    () => getCountCooptationsByEntity({ startDate, endDate }),
    [getCountCooptationsByEntity, startDate, endDate]
  );

  const setDatesRange = useCallback(
    (key) => (value) => {
      const isStartDate = key === 'startDate';

      let date = new Date(value);
      date = new Date(date.setMonth(date.getMonth() + (isStartDate ? 6 : -6)));
      date = date.toISOString().slice(0, 10);

      setValue(isStartDate ? 'endDate' : 'startDate', date);
    },
    [setValue]
  );

  // CHANGES
  useEffect(() => {
    setDatesRange('endDate')(getValues('endDate'));
  }, [getValues, setDatesRange]);

  return (
    <>
      <Container>
        <Row justifyContent="space-between" alignItems="center" gutter={8} pt={4}>
          <Box as="form" px={8} display="flex" onSubmit={handleSubmit(() => null)}>
            <Field
              type="date"
              {...register('startDate')}
              onValueChange={setDatesRange('startDate')}
              label={t('from')}
              mr={4}
            />
            <Field type="date" {...register('endDate')} onValueChange={setDatesRange('endDate')} label={t('to')} />
          </Box>
          {/* <Box px={8}>
            <Button variant="tertiary">
              <Icon name="MdOutlinePrint" size={20} mr={2} /> {t('print')}
            </Button>
          </Box> */}
        </Row>
        <Box width={1} height="1px" bg="dark" />
      </Container>
      <Container py={100}>
        <Row gutter={8}>
          <Box width={[1, null, 1 / 2]} p={8}>
            <Heading mb={8}>{t('ratioCooptationsHired')}</Heading>
            {ratioCooptationsHired.length === 2 && (
              <Row gutter={2}>
                <Box m={2} p={4} bg="green" borderRadius={5}>
                  <Text fontFamily="GSBold" mb={2} lineHeight={1} color="light" fontSize={70}>
                    {ratioCooptationsHired?.[0]?.value}
                  </Text>
                  <Text color="light" fontSize={3}>
                    {t('recrutedCooptations')}
                  </Text>
                </Box>
                <Box m={2} p={4} bg="#fdbf37" borderRadius={5}>
                  <Text fontFamily="GSBold" mb={2} lineHeight={1} color="light" fontSize={70}>
                    {ratioCooptationsHired?.[1]?.value}
                  </Text>
                  <Text color="light" fontSize={3}>
                    {t('refusedCooptations')}
                  </Text>
                </Box>
                <Box m={2} p={4} bg="blue" borderRadius={5}>
                  <Text fontFamily="GSBold" mb={2} lineHeight={1} color="light" fontSize={70}>
                    {ratioCooptationsHired[0].value + ratioCooptationsHired[1].value}
                  </Text>
                  <Text color="light" fontSize={3}>
                    {t('totalCooptations')}
                  </Text>
                </Box>
              </Row>
            )}
          </Box>
          <Box
            width={[1, null, 1 / 2]}
            minHeight="370px"
            p={8}
            display="flex"
            alignItems="center"
            justifyContent="center">
            <CircleChart data={ratioCooptationsHired} colors={['#669D92', '#fdbf37']} />
          </Box>
        </Row>
      </Container>
      <Container py={100} bg="background">
        <Row gutter={8}>
          <Box width={[1, null, 1 / 2]} p={8}>
            <Heading mb={8}>{t('countCooptationsByHr')}</Heading>
            <Table data={countCooptationsByHr} columns={columnsCooptedByHR} />
          </Box>
          <Box
            width={[1, null, 1 / 2]}
            minHeight="370px"
            p={8}
            display="flex"
            alignItems="center"
            justifyContent="center">
            <CircleChart data={countCooptationsByHr} colors={['#2B9DE5', '#669D92', '#f15326', '#fdbf37']} />
          </Box>
        </Row>
      </Container>
      <Container py={100}>
        <Row gutter={8}>
          <Box width={[1, null, 1 / 2]} p={8}>
            <Heading mb={8}>{t('countCooptationsByEntity')}</Heading>
            <Table data={countCooptationsByEntity} columns={columnsCountCooptationByEntity} mb={10} />
          </Box>
          <Box width={[1, null, 1 / 2]} p={8} display="flex" justifyContent="center" alignItems="center">
            <ChartBar data={countCooptationsByEntity} />
          </Box>
        </Row>
      </Container>
    </>
  );
};

Dashboard.propTypes = {
  ratioCooptationsHired: array.isRequired,
  getRatioCooptationsHired: func.isRequired,
  countCooptationsByHr: array.isRequired,
  getCountCooptationsByHr: func.isRequired,
  countCooptationsByEntity: array.isRequired,
  getCountCooptationsByEntity: func.isRequired
};

const mapState = ({ kpi }) => ({
  ratioCooptationsHired: kpi.ratioCooptationsHired || [],
  countCooptationsByHr: kpi.countCooptationsByHr || [],
  countCooptationsByEntity: kpi.countCooptationsByEntity || []
});

const mapDispatch = ({ kpi }) => ({
  getRatioCooptationsHired: kpi.getRatioCooptationsHired,
  getCountCooptationsByHr: kpi.getCountCooptationsByHr,
  getCountCooptationsByEntity: kpi.getCountCooptationsByEntity
});

export default connect(mapState, mapDispatch)(Dashboard);

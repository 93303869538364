/** @jsxRuntime classic */
/** @jsx jsx */

// eslint-disable-next-line no-unused-vars
import { jsx, useTheme } from '@emotion/react';
import { Suspense, forwardRef, lazy, useMemo } from 'react';
import { array, func, number, object, oneOfType, string } from 'prop-types';
import { omit } from '@styled-system/props';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import libraries from './Icon.helpers';
import getCSS from './Icon.styled';

const Icon = forwardRef(({ name, size, library, className, ...props }, ref) => {
  const theme = useTheme();
  const NewIcon = useMemo(
    () =>
      lazy(() =>
        libraries[library]().then((module) => ({
          default: styled(module[name], {
            shouldForwardProp: (prop) => isPropValid(prop) || prop === 'as'
          })``
        }))
      ),
    [name, library]
  );

  const sharedProps = {
    theme,
    size,
    minWidth: size,
    minHeight: size,
    ...props
  };

  const styles = getCSS(sharedProps);

  return (
    // eslint-disable-next-line react/no-unknown-property
    <Suspense fallback={<svg css={styles} />}>
      <NewIcon css={styles} ref={ref} className={className} {...omit(props)} />
    </Suspense>
  );
});

Icon.propTypes = {
  /** fallback element to render as */
  as: oneOfType([string, object, func]),
  /** styled-system size prop */
  size: oneOfType([number, string, array, object]),
  /** styled-system position prop */
  position: oneOfType([string, array, object]),
  /** styled-system verticalAlign prop */
  verticalAlign: oneOfType([string, array, object]),
  /** icon name, fetches icons from the react-icons library */
  name: string,
  /** library name */
  library: string
};

Icon.defaultProps = {
  as: 'svg',
  size: 18,
  position: 'relative',
  verticalAlign: 'middle',
  name: 'MdHome',
  library: 'md'
};

export default Icon;

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { array, bool, func, number } from 'prop-types';
import { pathOr, propOr } from 'ramda';

import { UserContext } from 'context';

import { getOrderParams } from 'utils/api';

import { Box, Button, Icon, Row } from 'components/atoms';
import { Modal, Pagination, SearchField, Table } from 'components/molecules';
import { UserForm } from 'components/organisms';
import { AdminMenu } from 'pages';

const Users = ({ getItems, updateItem, deleteItem, list, listCount, isFetchingList, isUpdating, isDeleting }) => {
  const { t } = useTranslation();
  const currentUser = useContext(UserContext);
  const [itemEdited, setItemEdited] = useState({});
  const [currentAction, setCurrentAction] = useState(null);
  const [criteria, setCriteria] = useState({
    page: 1,
    limit: 20,
    order: 'lastName,firstName',
    count: true
  });
  const [where, setWhere] = useState({
    search: {
      fields: ['firstName', 'lastName', 'email', 'entity.name'],
      text: ''
    }
  });

  const columns = useMemo(
    () => [
      { Header: t('user:lastName'), accessor: 'lastName' },
      { Header: t('user:firstName'), accessor: 'firstName' },
      { Header: t('user:email'), accessor: 'email' },
      { Header: t('user:entity'), accessor: 'entity.name' },
      { Header: t('user:role'), accessor: 'role', Cell: ({ value }) => t(`user:roles.${value}`) },
      {
        id: 'actions',
        cellProps: { width: '1px', p: 0 },
        Cell: (rowData) => (
          <Row flexWrap="nowrap" height="100%">
            <Button variant="secondary" bare iconOnly borderRadius="0" title={t('user:actions.edit')}>
              <Icon name="MdEdit" />
            </Button>
            <Button
              variant="secondary"
              bare
              iconOnly
              borderRadius="0"
              title={t('user:actions.delete')}
              onClick={(e) => {
                e.stopPropagation();
                setItemEdited(pathOr({}, ['row', 'original'], rowData));
                setCurrentAction('delete');
              }}>
              <Icon name="MdDelete" />
            </Button>
          </Row>
        )
      }
    ],
    [t]
  );

  const onSearchChange = useCallback(
    (text) => setWhere((current) => ({ ...current, search: { ...current.search, text } })),
    []
  );

  const onSort = useCallback((params) => {
    setCriteria((current) => ({ ...current, order: getOrderParams('lastName,firstName', params) }));
  }, []);

  const closeModal = async (item) => {
    if (item?.userId) {
      if (currentAction === 'edit') await updateItem(item);
      else if (currentAction === 'delete') await deleteItem(item.userId);

      await getItems(criteria);
    }
    setCurrentAction(null);
    setItemEdited(null);
  };

  useEffect(() => {
    getItems({ ...criteria, where });
  }, [getItems, criteria, where]);

  return (
    <Row justifyContent="center">
      <Box p="4" width="100%">
        <Row justifyContent="space-between" alignItems="center" mb="2">
          <Box py="3">
            <AdminMenu selected="users" />
          </Box>
          <Row>
            <SearchField
              width={[1, 'auto']}
              maxWidth={['initial', '300px']}
              my="2"
              onChange={(val) => onSearchChange(val)}
            />
          </Row>
        </Row>
        <Table
          sortable
          onSort={onSort}
          isLoading={isFetchingList}
          mb="5"
          tableParams={{
            initialState: {
              sortBy: [
                { id: 'lastName', desc: false },
                { id: 'firstName', desc: false }
              ]
            }
          }}
          getRowProps={({ original: user }) => ({
            onClick: () => {
              setItemEdited(user);
              setCurrentAction('edit');
            }
          })}
          data={list}
          columns={columns}
        />
        <Pagination
          page={criteria.page}
          pages={Math.ceil(listCount / criteria.limit)}
          onChange={(data) => setCriteria((curr) => ({ ...curr, page: data.page }))}
        />
      </Box>
      <Modal
        show={currentAction === 'edit'}
        onClose={closeModal}
        title={t('user:actions.edit')}
        actions={[
          (props) => <Button {...props} />,
          (props) => (
            <Button
              {...props}
              autoFocus
              variant="primary"
              form="userForm"
              type="submit"
              isLoading={!!(isUpdating || isFetchingList)}
              onClick={() => null}>
              {t('general:save')}
            </Button>
          )
        ]}>
        <UserForm user={itemEdited} type={currentUser.role} onSubmit={closeModal} hideActions id="userForm" />
      </Modal>
      <Modal
        show={currentAction === 'delete'}
        onClose={closeModal}
        title={t('user:actions.delete')}
        actions={[
          (props) => <Button {...props} />,
          (props) => (
            <Button
              {...props}
              autoFocus
              variant="primary"
              onClick={() => closeModal(itemEdited)}
              isLoading={!!(isDeleting || isFetchingList)}>
              {t('general:confirm')}
            </Button>
          )
        ]}>
        <Box my={2} as="p">
          {t('user:actions.deleteConfirmation', {
            firstName: propOr('', 'firstName', itemEdited),
            lastName: propOr('', 'lastName', itemEdited)
          })}
        </Box>
      </Modal>
    </Row>
  );
};

Users.propTypes = {
  list: array.isRequired,
  listCount: number.isRequired,
  getItems: func.isRequired,
  updateItem: func.isRequired,
  deleteItem: func.isRequired,
  isFetchingList: bool.isRequired,
  isUpdating: bool.isRequired,
  isDeleting: bool.isRequired
};

const mapState = ({ user, loading }) => ({
  list: user.list,
  listCount: user.listGlobalCount || 0,
  isFetchingList: loading?.effects?.user?.getUsers || false,
  isUpdating: loading?.effects?.user?.update || false,
  isDeleting: loading?.effects?.user?.delete || false
});

const mapDispatch = ({ user }) => ({
  getItems: user.getUsers,
  updateItem: user.update,
  deleteItem: user.delete
});

export default connect(mapState, mapDispatch)(Users);

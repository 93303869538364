import { external, hr1, hr2, user } from './user';
import { entities } from './entity';
import { hrProcess } from './hr-process';

// eslint-disable-next-line import/prefer-default-export
export const cooptations = [
  {
    cooptedId: 1,
    firstName: 'Niou',
    lastName: 'Ouane',
    email: 'coopted@gmail.com',
    contractStart: new Date(),
    cv: 'https://www.buds.com.ua/images/Lorem_ipsum.pdf',
    motivationLetter: 'https://www.buds.com.ua/images/Lorem_ipsum.pdf',
    workPermit: false,
    commentary: 'seems to be a good profil !',
    entity: entities[5],
    cooptor: external,
    hr: hr1,
    hrProcess: hrProcess[3],
    job: {
      name: 'Digital Business Analyst'
    }
  },
  {
    cooptedId: 2,
    firstName: 'Sek-Hond',
    lastName: 'Wanh',
    email: 'coopted2@gmail.com',
    contractStart: new Date('2023-03-1'),
    workPermit: true,
    entity: entities[3],
    cooptor: hr2,
    hr: hr2,
    hrProcess: hrProcess[2],
    job: {
      name: 'Cloud Engineer'
    }
  },
  {
    cooptedId: 2,
    firstName: 'Rahn',
    lastName: 'Domme',
    email: 'coopted3@gmail.com',
    contractStart: new Date(),
    workPermit: true,
    cooptor: user,
    hr: hr1,
    hrProcess: hrProcess[1]
  }
];

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Heading, Link, Text } from 'components/atoms';

const Error = () => {
  const { t } = useTranslation('errors');
  const { code } = useParams();

  return (
    <Box textAlign="center" py="5">
      <Heading mb="5">{t(`errors:${code}.title`)}</Heading>
      <Text mb="5">{t(`errors:${code}.subtitle`)}</Text>
      <Link to="/">
        <span>{t([`errors:${code}.link`, 'errors:goBackHome'])}</span>
      </Link>
    </Box>
  );
};

export default Error;

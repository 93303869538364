import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { array, bool, func, number } from 'prop-types';
import { pathOr } from 'ramda';

import { getOrderParams } from 'utils/api';

import { Box, Button, Icon, Row } from 'components/atoms';
import { Modal, Pagination, SearchField, Table } from 'components/molecules';
import { CountryForm } from 'components/organisms';
import { AdminMenu } from 'pages';

const Countries = ({
  getItems,
  createItem,
  updateItem,
  deleteItem,
  list,
  listCount,
  isFetchingList,
  isUpdating,
  isDeleting
}) => {
  const { t } = useTranslation();
  const [itemEdited, setItemEdited] = useState({});
  const [currentAction, setCurrentAction] = useState(null);
  const [criteria, setCriteria] = useState({
    page: 1,
    limit: 20,
    order: 'name',
    count: true
  });
  const [where, setWhere] = useState({
    search: {
      fields: ['name'],
      text: ''
    }
  });

  const columns = useMemo(
    () => [
      { Header: t('general:name'), accessor: 'name' },
      {
        id: 'actions',
        cellProps: { width: '1px', p: 0 },
        Cell: (rowData) => (
          <Row flexWrap="nowrap" height="100%">
            <Button variant="secondary" bare iconOnly borderRadius="0">
              <Icon name="MdEdit" />
            </Button>
            <Button
              variant="secondary"
              bare
              iconOnly
              borderRadius="0"
              onClick={(e) => {
                e.stopPropagation();
                setItemEdited(pathOr({}, ['row', 'original'], rowData));
                setCurrentAction('delete');
              }}>
              <Icon name="MdDelete" />
            </Button>
          </Row>
        )
      }
    ],
    [t]
  );

  const onSearchChange = useCallback(
    (text) => setWhere((current) => ({ ...current, search: { ...current.search, text } })),
    []
  );

  const onSort = useCallback((params) => {
    setCriteria((current) => ({ ...current, order: getOrderParams('name', params) }));
  }, []);

  const closeModal = async (item) => {
    if (currentAction === 'create' && item?.name) {
      await createItem(item);

      await getItems();
    } else if (item?.countryId) {
      if (currentAction === 'edit') await updateItem(item);
      else if (currentAction === 'delete') await deleteItem(item.countryId);

      await getItems(criteria);
    }
    setCurrentAction(null);
    setItemEdited(null);
  };

  useEffect(() => {
    getItems({ ...criteria, where });
  }, [getItems, criteria, where]);

  return (
    <Row justifyContent="center">
      <Box p="4" width="100%">
        <Row justifyContent="space-between" alignItems="center" mb="2">
          <Box py="12px">
            <AdminMenu selected="countries" />
          </Box>
          <Row>
            <SearchField
              width={[1, 'auto']}
              maxWidth={['initial', '300px']}
              my="2"
              onChange={(val) => onSearchChange(val)}
            />
            <Button
              type="button"
              onClick={() => setCurrentAction('create')}
              height="32px"
              max-height="60px"
              ml={3}
              mt={2}>
              {t('general:create')}
            </Button>
          </Row>
        </Row>
        <Table
          sortable
          onSort={onSort}
          isLoading={isFetchingList}
          mb="5"
          tableParams={{
            initialState: {
              sortBy: [{ id: 'name', desc: false }]
            }
          }}
          getRowProps={({ original: item }) => ({
            onClick: () => {
              setItemEdited(item);
              setCurrentAction('edit');
            }
          })}
          data={list}
          columns={columns}
        />
        <Pagination
          page={criteria.page}
          pages={Math.ceil(listCount / criteria.limit)}
          onChange={(data) => setCriteria((curr) => ({ ...curr, page: data.page }))}
        />
      </Box>
      <Modal
        show={['create', 'edit'].includes(currentAction)}
        onClose={closeModal}
        title={t('general:edit')}
        actions={[
          (props) => <Button {...props} />,
          (props) => (
            <Button
              {...props}
              autoFocus
              variant="primary"
              form="countryForm"
              type="submit"
              isLoading={!!(isUpdating || isFetchingList)}
              onClick={() => null}>
              {t('general:save')}
            </Button>
          )
        ]}>
        <CountryForm country={itemEdited} onSubmit={closeModal} />
      </Modal>
      <Modal
        show={currentAction === 'delete'}
        onClose={closeModal}
        title={t('general:delete')}
        actions={[
          (props) => <Button {...props} />,
          (props) => (
            <Button
              {...props}
              autoFocus
              variant="primary"
              onClick={() => closeModal(itemEdited)}
              isLoading={!!(isDeleting || isFetchingList)}>
              {t('general:confirm')}
            </Button>
          )
        ]}>
        <Box my={2} as="p">
          {t('general:confirmDelete')}
        </Box>
      </Modal>
    </Row>
  );
};

Countries.propTypes = {
  list: array.isRequired,
  listCount: number.isRequired,
  getItems: func.isRequired,
  createItem: func.isRequired,
  updateItem: func.isRequired,
  deleteItem: func.isRequired,
  isFetchingList: bool.isRequired,
  isUpdating: bool.isRequired,
  isDeleting: bool.isRequired
};

const mapState = ({ country, loading }) => ({
  list: country.list,
  listCount: country.listGlobalCount || 0,
  isFetchingList: loading?.effects?.country?.getCountries || false,
  isUpdating: loading?.effects?.country?.updateCountry || false,
  isDeleting: loading?.effects?.country?.deleteCountry || false
});

const mapDispatch = ({ country }) => ({
  getItems: country.getCountries,
  createItem: country.createCountry,
  updateItem: country.updateCountry,
  deleteItem: country.deleteCountry
});

export default connect(mapState, mapDispatch)(Countries);

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { useNavigate } from 'react-router-dom';

import { UserContext } from 'context';

import { Box, Button, Card, Heading, Icon, Row } from 'components/atoms';
import { UserForm } from 'components/organisms';

const Profile = ({ isLoading, update }) => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { t } = useTranslation(['general', 'user']);

  const onSubmit = async (userData) => {
    const result = await update(userData);
    if (result) navigate(-1);
  };

  return (
    <Row justifyContent="center">
      <Box p="4" maxWidth="400px" width="100%">
        <Card bg="background" boxShadow={0} borderWidth={1} borderStyle="solid" borderColor="dark">
          <Row justifyContent="space-between" alignItems="center">
            <Heading mb="2">{t('user:profile')}</Heading>
            <Button bare onClick={() => navigate(-1)} mb="2">
              <Icon name="MdChevronLeft" /> {t('general:back')}
            </Button>
          </Row>
          <UserForm onSubmit={onSubmit} submitting={isLoading} user={user} />
        </Card>
      </Box>
    </Row>
  );
};

Profile.propTypes = {
  isLoading: bool.isRequired,
  update: func.isRequired
};

const mapState = ({ loading }) => ({
  isLoading: pathOr(false, ['effects', 'user', 'update'], loading)
});

const mapDispatch = (dispatch) => ({
  update: dispatch.user.update
});

export default connect(mapState, mapDispatch)(Profile);

const MAX_FILE_SIZE = 10; // mb

const FILE_EXTENSIONS = {
  pdf: ['pdf'],
  doc: ['doc', 'docx', 'odt'],
  image: ['jpeg', 'jpg', 'png']
};

const FILE_TYPES = {
  pdf: ['application/pdf'],
  doc: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text'
  ],
  image: ['image/jpeg', 'image/png']
};

export function generateValidExtensions(validTypes = []) {
  let validExtensions = '';
  validTypes.forEach((type) => {
    FILE_EXTENSIONS[type]?.forEach((extension) => {
      validExtensions += `.${extension},`;
    });
  });

  return validExtensions;
}

function isValidType(file, validTypes = []) {
  if (typeof file === 'string' || !file) return true;
  return validTypes.some((validType) => FILE_TYPES[validType]?.some((t) => t === file?.type));
}

function isValidSize(file, maxSize = MAX_FILE_SIZE) {
  if (typeof file === 'string' || !file) return true;
  return file?.size < maxSize * 1000 * 1000;
}

export const fileValidation = {
  file: (required, types, maxSize) => ({
    required: required ? 'required' : required,
    validate: {
      type: (file) => (isValidType(file, types) ? true : 'invalidType'),
      size: (file) => (isValidSize(file, maxSize) ? true : 'fileTooLarge')
    }
  })
};

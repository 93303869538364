import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Button, Label } from 'components/atoms';

const Ping = ({ ping }) => {
  const { t } = useTranslation('ping');
  return (
    <>
      <Label>{t('ping:title')}</Label>
      <Button type="button" onClick={ping} width={[1 / 2, 'auto']}>
        Ping
      </Button>
    </>
  );
};

Ping.propTypes = {
  ping: func.isRequired
};

const mapDispatch = ({ ping: { ping } }) => ({
  ping
});

export default connect(null, mapDispatch)(Ping);

import * as service from './service';

const hrProcess = {
  state: {
    list: [],
    listGlobalCount: 0
  },
  reducers: {
    listUpdated(state, payload) {
      return {
        ...state,
        list: payload.rows || payload,
        listGlobalCount: payload.count || payload.length
      };
    }
  },
  effects: {
    async getHrProcess(criteria) {
      const list = await service.getHrProcess(criteria);
      if (list) this.listUpdated(list);
      return list;
    }
  }
};

export default hrProcess;

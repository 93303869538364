import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { boxProps, ellipsis } from 'components/helpers';

export default {
  Link: styled(Link)`
    ${ellipsis}
    ${boxProps}
    position: relative;

    &:active,
    &:hover {
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    outline: none;
  `
};

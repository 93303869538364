import { keys, values } from 'ramda';

const baseTheme = {
  breakpoints: { xs: '450px', sm: '750px', md: '1050px', lg: '1350px', xl: '1650px' },
  space: ['0px', '4px', '8px', '12px', '16px', '20px', '24px', '28px', '32px', '36px', '40px'],
  fontSizes: ['1rem', '1.2rem', '1.4rem', '1.6rem', '1.8rem', '2rem', '2.4rem', '3rem', '3.2rem'],
  fontWeights: ['400', '600'],
  fonts: {
    GSMedium: 'General Sans Medium, sans-serif',
    GSSemibold: 'General Sans Semibold, sans-serif',
    GSBold: 'General Sans Bold, sans-serif',
    ExconMedium: 'Excon Medium, sans-serif',
    ExconBold: 'Excon Bold, sans-serif'
  },
  lineHeight: '1.5',
  transition: '.2s linear',
  radii: ['0px', '2px'],
  elementSizes: ['28px', '36px'],
  headerHeight: ['200px', '100px'],
  colors: {
    primary: '#ff1738',
    secondary: 'rgba(0,0,0,.1)',
    light: '#fff',
    grays: ['rgba(0,0,0,.6)', 'rgba(0,0,0,.3)', 'rgba(0,0,0,.1)', '#f5f5f5'],
    dark: '#000000',
    yellow: '#f5ed63',
    green: '#669D92',
    red: '#F15225',
    blue: '#2B9DE5',
    blanket: 'rgba(0, 0, 0, .3)',
    background: '#fdf6e6'
  },
  borderWidths: ['0px', '2px'],
  borders: ['none', '2px solid rgba(0,0,0,.1)'],
  shadows: ['none', '0px 2px 4px 0px rgba(0, 0, 0, 0.15)']
};

export const createTheme = ({ breakpoints, ...params }) => {
  const breakpointsArray = values(breakpoints);
  const extendedTheme = {
    ...params,
    breakpoints: breakpointsArray,
    breakpointsKeys: keys(breakpoints),
    mimicLabel: `calc(${params.lineHeight}em + ${params.space[1]})`,
    mq: breakpointsArray.map((bp) => `@media (min-width: ${bp})`)
  };
  extendedTheme.breakpointsKeys.forEach((key) => {
    extendedTheme.breakpoints[key] = breakpoints[key];
  });
  return extendedTheme;
};

export default baseTheme;

import { api, secureCall } from 'utils/api';

const route = 'entities';

export const getEntities = secureCall((params) => api.get(route, { params }));

export const getEntity = secureCall((id) => api.get(`${route}/${id}`));

export const createEntity = secureCall((payload) => api.post(route, payload));

export const updateEntity = secureCall((payload) => api.patch(`${route}/${payload.entityId}`, payload));

export const deleteEntity = secureCall((id) => api.delete(`${route}/${id}`, { data: {} }));

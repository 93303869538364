import { forwardRef } from 'react';
import { array, node, number, object, oneOfType, string } from 'prop-types';
import { Box, Row } from 'components/atoms';

const Container = forwardRef(({ children, ...props }, ref) => (
  <Row ref={ref} {...props}>
    <Box px={8} maxWidth="1400px" width="100%">
      {children}
    </Box>
  </Row>
));

Container.propTypes = {
  /** will set the width (calc(100% + theme.space[gutter] * 2)) and negative margin (theme.space[gutter]) on each side to create gutters between its children */
  justifyContent: oneOfType([number, string, array, object]),
  /** styled system display prop */
  bg: oneOfType([string, array, object]),
  children: node
};

Container.defaultProps = {
  justifyContent: 'center',
  bg: 'light',
  children: null
};

export default Container;

import { useTranslation } from 'react-i18next';

import { AnimateCircle, Box, Heading, Picture, Row, Text } from 'components/atoms';
import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { UserContext } from 'context';

import { func, object } from 'prop-types';
import Hands from 'assets/images/hands.svg';
import Blue from 'assets/images/picto-blue.svg';
import Green from 'assets/images/picto-green.svg';
import BonusParagraph from './BonusParagraph/BonusParagraph';

const HomePage = ({ bonus, getBonus, getEntities }) => {
  const { t } = useTranslation();
  const currentUser = useContext(UserContext);

  useEffect(() => {
    getEntities({ where: { countryId: currentUser.countryId } });
  }, [getEntities, currentUser]);

  useEffect(() => {
    getBonus();
  }, [getBonus]);

  return (
    <>
      <Row bg="dark" justifyContent="center" overflow="hidden" position="relative">
        <AnimateCircle />
        <Box px={[8, null, null, 230]} py={100} maxWidth="1400px" width="100%" position="relative">
          <Heading
            fontFamily="GSBold"
            color="background"
            textAlign="center"
            textTransform="uppercase"
            fontSize={4}
            mb={5}>
            {t('home:paragraph1Title')}
          </Heading>
          <Text color="background" textAlign="center" fontFamily="ExconMedium" fontSize={6} mb={7}>
            {t('home:paragraph1.1')}
          </Text>
          <Text color="background" textAlign="center" fontFamily="ExconMedium" fontSize={6}>
            {t('home:paragraph1.2')}
          </Text>
        </Box>
      </Row>
      <Row bg="light" justifyContent="center">
        <Box py={100} maxWidth="1400px" width="100%" px={8}>
          <Row gutter={8} alignItems="center">
            <Box width={[1, null, 1 / 2]} px={8}>
              <Heading fontFamily="GSBold" textTransform="uppercase" fontSize={4} mb={5}>
                {t('home:paragraph2Title')}
              </Heading>
              <Text fontSize={8} mt={3} mb={7} dangerouslySetInnerHTML={{ __html: t('home:paragraph2.3') }} />
            </Box>
            <Box width={(1, null, 1 / 2)} display={['none', null, 'block']} px={8}>
              <Picture src={Hands} width={1} />
            </Box>
          </Row>
        </Box>
      </Row>

      <Row bg="background" justifyContent="center">
        <Box py={100} maxWidth="1400px" width="100%" px={8}>
          <Row gutter={8}>
            <Box
              width={[1, null, 1 / 2]}
              mb={[8, null, 0]}
              px={8}
              display="flex"
              flexDirection="column"
              alignItems="center">
              <Picture src={Blue} width={60} height={60} mb={5} />
              <Heading mb={5} textAlign="center">
                {t('home:paragraph3Title')}
              </Heading>
              <Text fontSize={3} mb={2} textAlign="center">
                {t('home:paragraph3.1')}
              </Text>
              <Text fontSize={3} mb={2} textAlign="center">
                {t('home:paragraph3.2')}
              </Text>
              <Text fontSize={3} mb={2} textAlign="center">
                {t('home:paragraph3.3')}
              </Text>
              <Text fontSize={3} textAlign="center">
                {t('home:paragraph3.4')}
              </Text>
            </Box>
            <Box width={[1, null, 1 / 2]} px={8} display="flex" flexDirection="column" alignItems="center">
              <Picture src={Green} width={60} height={60} mb={5} />
              <Heading mb={5} textAlign="center">
                {t('home:paragraph4Title')}
              </Heading>
              <BonusParagraph bonus={bonus} />
            </Box>
          </Row>
        </Box>
      </Row>
    </>
  );
};

HomePage.propTypes = {
  getBonus: func.isRequired,
  getEntities: func.isRequired,
  bonus: object.isRequired
};

const mapState = ({ bonus, entity }) => ({
  entities: entity.list || [],
  bonus: bonus.result || {}
});

const mapDispatch = ({ bonus, entity }) => ({
  getEntities: entity.getEntities,
  getBonus: bonus.getBonus
});

export default connect(mapState, mapDispatch)(HomePage);

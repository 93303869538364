import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { any, array, bool, func, node, number, object, oneOfType, string } from 'prop-types';
import { createPortal } from 'react-dom';
import FocusTrap from 'focus-trap-react';

import { usePrevious, useWindowDimensions } from 'hooks';

import Styled, { getLayoutProps } from './Dropdown.styled';

const Dropdown = ({
  width,
  attachEl,
  automaticPosition,
  update,
  children,
  onClickOutside,
  onKeyDown,
  focusTrapActive,
  focusTrapOptions,
  focusableCloseButton,
  onRender,
  ...props
}) => {
  const { height: winHeight, width: winWidth } = useWindowDimensions();
  const ref = useRef(null);
  const prevUpdate = usePrevious(update);

  const getLayout = useCallback(
    () => getLayoutProps(attachEl.current, ref.current, automaticPosition, { winHeight, winWidth }),
    [attachEl, ref, automaticPosition, winHeight, winWidth]
  );

  const [layoutProps, setLayoutProps] = useState(null);

  const clickOutside = useCallback(
    /* istanbul ignore next */
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside(event);
      }
    },
    [ref, onClickOutside]
  );

  const scrollOutside = useCallback(
    /* istanbul ignore next */
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setLayoutProps(getLayout());
      }
    },
    [ref, getLayout]
  );

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    if (attachEl.current) document.addEventListener('scroll', scrollOutside, true);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
      document.removeEventListener('scroll', scrollOutside, true);
    };
  }, [clickOutside, scrollOutside, attachEl]);

  useLayoutEffect(() => {
    setLayoutProps(getLayout());
  }, [getLayout, update, prevUpdate]);

  useLayoutEffect(() => {
    onRender();
  });

  return createPortal(
    <Styled.Dropdown
      ref={ref}
      aria-hidden
      onMouseDown={(event) => event.preventDefault()}
      width={width}
      {...layoutProps}
      {...props}>
      <FocusTrap
        active={focusTrapActive}
        focusTrapOptions={{
          preventScroll: true,
          ...focusTrapOptions
        }}>
        <Styled.Content
          aria-hidden
          onKeyDown={(event) => {
            if (!onKeyDown) {
              return event.key === 'Escape' && onClickOutside(event);
            }
            return onKeyDown(event);
          }}>
          {focusableCloseButton && (
            <Styled.Close
              type="button"
              aria-label="Close"
              onClick={onClickOutside}
              onKeyDown={(e) => {
                if (e.key === 'Enter') e.preventDefault();
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') onClickOutside(e);
              }}
            />
          )}
          {children}
        </Styled.Content>
      </FocusTrap>
    </Styled.Dropdown>,
    document.body
  );
};

Dropdown.propTypes = {
  /** ref of the component dropdown is attached to */
  attachEl: object,
  /** enable positionning relative to attachEl */
  automaticPosition: bool,
  /** styled-system position prop */
  position: oneOfType([string, array, object]),
  /** styled-system z-index prop */
  zIndex: oneOfType([number, string, array, object]),
  /** styled-system width prop, dropdown will fit the attachEl if 'fit' */
  width: oneOfType([number, string, array, object]),
  /** on click outside of dropdown */
  onClickOutside: func,
  /** on content keydown */
  onKeyDown: func,
  /** force dropdown position update when this value changes (if automaticPosition) */
  update: any,
  /** is focustrap active (https://github.com/davidtheclark/focus-trap-react) */
  focusTrapActive: bool,
  /** focustrap options (https://github.com/davidtheclark/focus-trap-react) */
  focusTrapOptions: object,
  /** hidden close button to have at least one focusable element (a focusable element in a focus trap is mandatory) */
  focusableCloseButton: bool,
  /** on each render */
  onRender: func,
  children: node
};

Dropdown.defaultProps = {
  attachEl: { current: null },
  automaticPosition: true,
  position: 'fixed',
  zIndex: 9999,
  width: 'fit',
  onClickOutside: () => null,
  onKeyDown: null,
  update: undefined,
  focusTrapActive: true,
  focusTrapOptions: null,
  focusableCloseButton: true,
  onRender: () => null,
  children: null
};

export default Dropdown;
